import React from 'react';
import { Card, Typography, Box, Button, rgbToHex } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Supportcenter from './Supportcenter';
import stallu from '../Components/Images/stallu.png'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ComingSoon = ({ courseArray }) => {
  return (
    <Card sx={{ padding: '8px' }}>
      <img src={stallu} alt="" style={{ width: '100%', height: 'auto' }} />
      <Box sx={{ padding: '0 8%', marginBottom: '25px' }}>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '1.65rem', fontWeight: '700', color: '#283034', textAlign: 'center', lineHeight: '1', margin: "10px 0px" }}>
        </Typography>

        
        <NavLink to={"/Terms"}>
          <Button>Register for Free</Button>
          <Typography align="center" sx={{ color: "#254d7b", pb: "5px", pt: "4px", fontWeight: 400 }}>T & C / Guideline</Typography>
        </NavLink>    
        <div style={{display:"flex"}}>
          {/* <Typography sx={{ fontSize: '1rem', marginTop: '10px', color: '#888', fontWeight: '600', fontFamily: 'Muli,sans-serif' }}>*Original price : ₹{" "}{courseArray.courseDetails.orginal} </Typography> */}

          <Typography sx={{ fontSize: '1rem', color: '#888', fontWeight: '600', fontFamily: 'Muli,sans-serif' }}>Fee :</Typography>
          <Typography sx={{ fontSize: '1.15rem', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif', textDecoration: 'line-through', marginLeft: '10px' , marginRight:"10px" }}> 
          {/* {courseArray.courseDetails.orginal != undefined ? (<span className="old-price"> {courseArray.courseDetails.orginal}</span>) : null} */}
          </Typography>
          <Typography sx={{ fontSize: '1rem', color: '#888', fontWeight: '600', fontFamily: 'Muli,sans-serif' }}> Coming Soon
          {/* {courseArray.courseDetails.price} */}
          </Typography>


        </div><div>

        </div>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px', marginTop: '20px' }}>
          This course includes:
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
        {/* {courseArray.courseDetails.duration.split(" ")[0]} {courseArray.courseType ? courseArray.courseType.slice(0, 4) : "week"}  */}
        course
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Certificate of Completion
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Progressive Assessments
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Final Assessment  
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Jobs mapped with course skills
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Mentor and peer to peer discussion window
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '0.9rem', fontWeight: '400', color: '#656565', lineHeight: '1', letterSpacing: '0.00938em', marginBottom: '8px' }}>
          Bangalore-based company hiring at 3-6  LPA
        </Typography>
      </Box>

    </Card>
  )
}

export default ComingSoon