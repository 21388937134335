import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav from './Components/Nav';
import Home from "./pages/Home";
import About from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import KeeweSolutions from "./pages/KeeweSolutions";
import Partnered from "./pages/PartneredOrganisation";
import Product from "./pages/ProductServices";
import Footer from './Components/Footer';
import CourseDetails from './Components/Coursepage/CourseDetail'
import TermsPage from './Components/Coursepage/TermsPage';
import Supportcenter from './Components/Supportcenter';
import Privacy from './Components/Privacy';
import ScrollTop from './Components/ScrollTop';
import Support from './pages/Support';
import Footer2 from './Components/Footer2';
import Nav2 from './Components/Nav2';
import SupportTry from './Components/SupportTry';
import APtable from './pages/APtable';
import Tally from './Components/Tally';
import CourseDetails1 from './Tallypage/CourseDetail1';
import Course from './Components/Course';
import CourseCards from './crestbell/crestbell';
import CourseDetails2 from './crestbell/courseDetails';



const App = () => {

  const loc1 = window.location.search.slice(1)
  console.log(window.location.search)
  

  return (

    <BrowserRouter>
    {!loc1?<>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/Keewe-solutions" element={<KeeweSolutions />} />
        <Route path="/Partnered-organisation" element={<Partnered />} />
        <Route path="/Product-services" element={<Product />} />
        <Route path="/Coursepage" element={<CourseDetails />} />
        <Route path="/Terms" element={<TermsPage />} />
        <Route path="/SupportCenter" element={<Support />} />
        <Route path="/Support-Center" element={<Supportcenter />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Product-services" element={<Product />} />
        <Route path="/APtable" element={<APtable />} />
        <Route path="/Tally" element={<Tally/>} />
        <Route path="/Tallypage" element={<CourseDetails1/>} />
        <Route path="/Course" element={<Course/>} />
        <Route path="/crestbell" element={<CourseCards/>} />
        <Route path="/Crestbellpage" element={<CourseDetails2/>} />

        
      </Routes>
      <Footer />
      <ScrollTop /></>:
      <>
      <Nav2/>
      <Routes>
        <Route path="/" element={<CourseDetails id={loc1} />} />
      </Routes>
      <Footer2/>
      </>
      }
    </BrowserRouter>
  )
}

export default App;