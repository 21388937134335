import React, { useEffect, useState } from 'react';
import { Button, Grid, Box, Typography, TextField } from '@mui/material'
import { NavLink } from "react-router-dom"
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import './map.css'



const CourseCards = () => {
    const [courseArray, setCourseArray] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [view, setView] = useState(false)
    const [value, setValue] = useState("")
    const get_courses = async () => {
        try {
            const results = await axios({
                url: "https://cms.keewesolutions.com/wp/get",
                params: {
                    "category": "Programming Language",
                },
                method: "GET"
            })
            console.log(results.data.data.Items)
            setCourseArray(results.data.data.Items)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllCourses = async () => {
        await axios({
            url: "https://cms.keewesolutions.com/allCourses",
            method: "POST"
        }).then(res => {
            setAllCourses(res.data.Items)
        })
    }

    useEffect(() => {
        get_courses();
        getAllCourses();
    }, [])

    return (
        <Grid container xs={12} lg={12} xl={12} sm={12} md={12} sx={{ backgroundColor: '#f8f9fb',marginTop:'4%' }}>
            <Grid lg={1} xl={1} sm={0.5} md={0.5} />
            <Grid item container xs={12} lg={10} xl={10} sm={11} md={11} marginTop='50px' marginBottom={'50px'}>
                <Grid item xs={12} lg={3} xl={4.5} sm={6} md={6} justifyContent='space-between' marginTop={'20px'}>
                    {/* <Typography  className='course' sx={{ fontSize: '1.65rem', fontWeight: '700', color: '#283034', fontFamily: 'Muli,sans-serif', paddingLeft: "10px"}}>All Courses</Typography> */}
{/* <select style={{border:'2px solid #f8f9fb', padding:'2%',boxShadow:'1px 2px 5px 1px rgb(0 0 0 / 20%)',backgroundColor:'#fff',fontSize:'20px',marginLeft:'4%',fontWeight:'700',color:'rgb(39 55 115)'}}>
<option value="volvo" >All Courses</option>
    <option value="saab">MRSPTU Courses</option>
    <option value="opel">Tally Courses</option>
</select> */}
                </Grid>
                <Grid item lg={4} xl={3.5} sm={3.5} md={3.5} xs={8}>

                    <Paper
                        component="form"

                        sx={{ padding: '2px 4px', display: 'flex', alignItems: 'center', mt: '20px', ml: "10px" }}
                    >

                        <InputBase
                            sx={{ mt: 1, flex: 1 }}
                            placeholder="    Search"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            onChange={(e)=>{
                                setValue(e.target.value)
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>

                    </Paper>

                </Grid>
                <Grid item xs={3.5} lg={2.8} xl={3.8} sm={2} md={2} sx={{
                    textAlign: "right", marginTop: '20px',

                }} >
                    {/* <NavLink to={''} style={{ textDecoration: 'none', display: 'flex' }}> */}
                    <Button variant='contained' onClick={() => {
                        setView(!view)
                    }} sx={{
                        backgroundColor: "rgb(39 55 115)",
                        border: '2 px solid #ff1f59',
                        '&:hover': { backgroundColor: '#62F4B9', color: '#020139' },
                        fontSize: '15px',
                        padding: '1px 15px',
                        height: '50px',
                        fontWeight: 'bold',
                        textTransform: "none",
                        color: 'white',
                        justifyContent: 'end'
                    }}> {!view ? "View All" : "View Less"}
                    </Button>
                    {/* </NavLink> */}
                </Grid>
                {view || value ? allCourses.filter((item)=>{
                    const regex = new RegExp(`${value}`,"gi");
                    const test = regex.test(item.title)
                    if(test){
                        return item
                    }
                }).map((item) => {
                    if (item.price != undefined) {
                        return (
                            <Grid item xs={12} lg={3} xl={3} sm={6} md={6} marginTop='40px' padding={"12px"}>
                                <NavLink
                                    to={'/Coursepage'} state={{ courseId: item.courseId }}
                                    style={{ textDecoration: 'none' }}>

                                    <Box sx={{ height: '500px', width: '100%', border: '1px solid #ddd', boxShadow: '0 50px 100px -20px rgb(50 50 93 / 25%)', textAlign: 'left', borderRadius: '10px', '&:hover': { boxShadow: '0 0 0 0' } }}>
                                        <img src={"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + item.courseId + ".png"} style={{ width: '100%', height: '230px', borderRadius: '5px' }} />
                                        <Box>
                                            <Grid marginTop={'8px'} marginLeft='5px' display='flex'>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Languages:</Box>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>English</Box>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Hindi</Box>
                                            </Grid>

                                            <Grid marginTop={'8px'} marginLeft='10px'>
                                                <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>{item.title}</Typography>
                                                <Typography sx={{ fontSize: '1.05rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>4.5 (26 Ratings)</Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>         {item.price != undefined ? <>Total Fee: ₹ {item.total} </> : <b></b>}<br />   </Typography>



                                                <div style={{ display: 'flex' }}>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>Uni. Fee : </Typography>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif', textDecoration: 'line-through', marginLeft: '10px' }}> &#8377; {item.orginal != undefined ? (<span className="old-price"> {item.orginal}</span>) : null}</Typography>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif', marginLeft: '10px' }}>{item.price != undefined ? <>₹ {item.price}</> : "COMING SOON"}</Typography>

                                                </div>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>ALSC Fee : ₹ {item.total - item.price}</Typography>

                                            </Grid>
                                        </Box>
                                        {/* <Button sx={{ color: 'blue' }}> &#62;&#62;go to Link</Button> */}
                                    </Box>
                                </NavLink>
                            </Grid>
                        )
                    }
                }) : courseArray.map((item) => {

                    return (
                        <Grid item xs={12} lg={3} xl={3} sm={6} md={6} marginTop='40px' padding={"12px"}>
                            <NavLink
                                to={'/Coursepage'} state={{ courseId: item.courseId }}
                                style={{ textDecoration: 'none' }}>

                                <Box sx={{ height: '500px', width: '100%', border: '1px solid #ddd', boxShadow: '0 50px 100px -20px rgb(50 50 93 / 25%)', textAlign: 'left', borderRadius: '10px', '&:hover': { boxShadow: '0 0 0 0' } }}>
                                    <img src={"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + item.courseId + ".png"} style={{ width: '100%', height: '230px', borderRadius: '5px' }} />
                                    <Box>
                                        <Grid marginTop={'8px'} marginLeft='5px' display='flex'>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Languages:</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>English</Box>
                                            <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Hindi</Box>
                                        </Grid>

                                        <Grid marginTop={'8px'} marginLeft='10px'>
                                            <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>{item.title}</Typography>
                                            <Typography sx={{ fontSize: '1.05rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>4.5 (26 Ratings)</Typography>
                                            <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>         {item.price != undefined ? <>Total Fee: ₹ {item.total} </> : <b></b>}<br />   </Typography>



                                            <div style={{ display: 'flex' }}>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>Uni. Fee : </Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif', textDecoration: 'line-through', marginLeft: '10px' }}> &#8377; {item.orginal != undefined ? (<span className="old-price"> {item.orginal}</span>) : null}</Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif', marginLeft: '10px' }}>{item.price != undefined ? <>₹ {item.price}</> : "COMING SOON"}</Typography>

                                            </div>
                                            <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>ALSC Fee : ₹ {item.total - item.price}</Typography>

                                        </Grid>
                                    </Box>
                                    {/* <Button sx={{ color: 'blue' }}> &#62;&#62;go to Link</Button> */}
                                </Box>
                            </NavLink>
                        </Grid>
                    )
                })}
            </Grid>

        </Grid>


    )
}

export default CourseCards;