import { Grid, Typography } from '@mui/material';
import React from 'react'
import marketing from './Images/marketing.jpg';
import './map.css'

const Product1 = () => {
  return (
    <Grid container sx={{mb:'6%',padding:'2%'}}>
                <Grid item xl={1.5} lg={0.8} md={0.2} sm={0.2}/>
                <Grid item xl={4} lg={4} xs={12} md={5} sm={5} sx={{ textAlign:'left'}}>
                    <img className="imaage" src={marketing} style={{marginTop:'6%', width: "550px" }} />
                </Grid>
                <Grid item md={0.6} sm={0.6}/>
                <Grid item xl={4.5} lg={4.7} xs={12} md={6} sm={6} sx={{marginTop:'4%'}}>
                    <Typography sx={{ fontSize: "25px", fontWeight: 600 , fontFamily:"Roboto", lineHeight:'1.2'}}>“Join Us & Provide the Extended Support to Your Companions”</Typography>
                    {/* <hr color="#3feba3" width="25%" align="left"/> */}
                    <Typography sx={{ fontSize: "16px", fontFamily: "Roboto", textAlign: "justify" ,marginTop:'3%'}}>
                      Register with the CRESTBELL Marketing & Logistics Support Centre and ease the efforts of students or institutions by fulfilling their needs or requirements. </Typography>
                    <Typography sx={{ fontSize: "16px", fontFamily: "Roboto", textAlign: "justify" ,marginTop:'3%'}}>
                      CRESTBELL, renowned as one of the best Ed-tech platforms in India provides multiple educational courses of various sectors under MRSPTU and you being established as the associate support centre need to deliver logistics support to the students, institutions, colleges, etc. for a hassle-free learning experience. You’ll also be eligible for the marketing of various linked educational products that are featured on CRESTBELL. 
                    </Typography>
                </Grid>
                <Grid item xl={2} lg={1}/>
            </Grid>
  )
} 

export default Product1
