import { Grid, Typography } from '@mui/material'
import React from 'react'
import pands from './Images/pands.png'
import './map.css'


const ProdServ = () => {
  return (
    <Grid container>
      <Grid item sx={{backgroundImage: `url(${pands})`, height: '550px', width:'100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative'}}>
      <Grid item sx={{backgroundColor: '#273773', opacity: 0.5, width: '100%', height: '100%'}}>
        </Grid>
        <Grid item className='firstcompo' sx={{position: 'absolute',top:"50%",left:"62%"}} >        
      <Typography  sx={{color:'white' ,fontSize: '3rem', fontWeight: 500, fontFamily: "Roboto"}}>Product & Services</Typography>
      <hr color="#3feba3" width="60%"/>
        
      </Grid>
      </Grid>
    </Grid>
  )
}

export default ProdServ
 