import { JoinFull } from '@mui/icons-material';
import React from 'react'
import FirstBox from '../Components/FirstBox';
import PartnerOrg from '../Components/PartnerOrg';
import Partner from '../Components/Product';
import Joinus from '../Components/Joinus'
import Po from '../Components/Po';


const PartneredOrganisation = () => {
  return (
    <div>
      {/* <PartnerOrg /> */}
      <Po/>
     
      <Joinus />
    </div>
  )
}

export default PartneredOrganisation
