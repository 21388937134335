import React from 'react';
import { Box, Typography, Card } from '@mui/material';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(1.5)' }}
  >
    •
  </Box>
);

const arr = [
  {
    des:' Basic to intermediate Accounting, Inventory and Basic Taxation (GST, TDS)'
  
  },
  {
    des:' Simplification of Company Books of Accounts to name a few.'
  
  },
  {
    des:' The practical applications will be done on the new Tally Prime software to keep you at par with the new releases.'
  
  },
 
] 

const Sql = ({ courseArray, teacher }) => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '2.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '0.5rem' }}>
        Tally Course
      </Typography>
      <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.125rem', fontWeight: '400', color: '#283034', marginBottom: '0.5rem' }}>
      The course is for beginners in the Accounting and Finance domain. It covers topics from
basic to intermediate Financial Accounting concepts by using Tally. The course is
explained illustrations, self-learning videos and real-life industry-based scenarios to
establish a strong foundation for your Accounting and Financial career.
      </Typography>
      {/* {teacher != undefined
        ? */}
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1rem', fontWeight: '400', color: '#888', marginBottom: '0.5rem' }}>
          Created by: Dr. Munish
        </Typography>
        
      <Card sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: '20px 0px 0px 0px', padding: '30px 25px', borderRadius: '10px' }}>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
          What you'll learn
        </Typography>
        {arr.map((item, index) => {
          return(<div style={{display:'flex'}}>
            <Typography>
            {bull} 
            </Typography> 
          <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px', ml:'1.5%' }}>
              {item.des}
          </Typography></div>)
        })}
        {/* <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}How to create database with SQL.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Learn how to code in SQL.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Handle SQL joins.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Learn to insert, update and delete records from the database.
        </Typography> */}
      </Card>
    </Box>

  )
}

export default Sql