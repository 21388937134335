import React from 'react';
import FirstBox from '../Components/FirstBox';
import ZonalCarousel from '../Zonal';
import CourseCards from '../Components/Course';
import SecondHome from '../Components/SecondHome';
import Expertise from '../Components/Expertise';
import Tally from '../Components/Tally';
import Try from './Try';
import Zonal2 from '../Zonal2';



const Home = () => {
  return (
    <div>
        
      <FirstBox/>
      <SecondHome/>
      <CourseCards />
    
      <ZonalCarousel />
   <Zonal2/>
  
    </div>
  )
}

export default Home
