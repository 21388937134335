import React from 'react';
import { Box, AppBar, Toolbar, IconButton, Button, Typography, Menu, MenuItem, Paper , Grid } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import logo from './Images/logo.png';
import { NavLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import "./Nav.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { height } from '@mui/system';

const Nav = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE, setAnchorE] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);


    const handleMenu1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenu2 = (event) => {
        setAnchorE2(event.currentTarget);
    };
    const handleMenu = (event) => {
        setAnchorE(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const handleClose2 = () => {
        setAnchorE2(null);
    };
    const handleClose = () => {
        setAnchorE(null);
    };

    return (
        <Grid sx={{marginBottom:'60px'}}>
            <Box sx={{ flexGrow: 1, zIndex: 100 }}>
                <AppBar sx={{ backgroundColor: "#273773 !important", height: "auto", boxShadow: '10px 0px 0px 0px gray' }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"

                            ia-label="menu"
                            sx={{
                                // marginBottom: '8px',
                                display: {
                                    lg: "none",
                                    xs: "block",
                                    md: "block",
                                    sm:"block"
                                }
                            }}
                            onClick={handleMenu1}

                        >
                            <MenuIcon sx={{ color: "white" }} />
                        </IconButton>
                        <Box sx={{ flexGrow:'1',display: 'flex', justifyContent: 'center' }}>
                            <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                                <img className='im' src={logo} alt="" style={{ width: '70%', height: 'auto'}} />
                            </NavLink>
                        </Box>
                        <Stack  alignContent={'center'} direction="row"
                            sx={{
                                display: {
                                    lg: 'flex',
                                    xs: "none",
                                    md:'none',
                                    sm:'none',
                                    xl:'flex'
                                
                                },
                                flexGrow:'1'
                               
                            }}>
                            <NavLink to={'/About'} style={{ textDecoration: 'none' }}>
                                {/* <a style={{textDecoration:'none',}} href = "https://www.crestbellsupport.com/about-us/"> */}
                                <Button>
                                    <Typography sx={{

                                        color: "white",
                                        textTransform: "capitalize",
                                        fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '50px',
                                        '&:hover': {
                                            background: "none  ",
                                            color: "#6c757d"
                                        }
                                    }
                                    } >About Us</Typography>
                                </Button>
                                {/* </a> */}
                            </NavLink>
                            {/* <NavLink to={'/ShabdLangar'} style={{ textDecoration: 'none' }}> */}
                            <a style={{ textDecoration: 'none', }} href='https://www.shabdlangar.org/'>
                                <Button>
                                    <Typography sx={{

                                        color: "white",
                                        textTransform: "capitalize",
                                        fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '50px',
                                        '&:hover': {
                                            background: "none  ",
                                            color: "#6c757d"
                                        }
                                    }
                                    } >ShabdLangar</Typography>
                                </Button>
                            </a>
                            {/* </NavLink> */}
                            <div >
                                <div class="navigation" >
                                    <NavLink to={'/Product-services'} style={{ textDecoration: 'none' }}>
                                        {/* <a style={{textDecoration:'none',}} href="https://www.crestbellsupport.com/product-services/"> */}
                                        <Button

                                            sx={{
                                                zIndex: 10000000,
                                                marginTop: '-7%'
                                            }}>

                                            <Typography sx={{

                                                color: "white",
                                                textTransform: "capitalize",
                                                fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                lineHeight: '50px',
                                                '&:hover': {
                                                    background: "none ",
                                                    color: "#6c757d"
                                                }
                                            }
                                            } >Product & Services </Typography>
                                            <KeyboardArrowDownIcon sx={{ color: 'white' }} />

                                        </Button>
                                    </NavLink>
                                    {/* </a> */}
                                    <div class="navigation-content">
                                        <NavLink to={'/Partnered-organisation'} state={{ category: 'Partnered-organisation' }} style={{ textDecoration: 'none' }}>Partnered Organisation</NavLink>
                                        <NavLink to={'/Keewe-solutions'} state={{ category: 'Keewe-solutions' }} style={{ textDecoration: 'none' }}>KEEWE Solutions</NavLink>

                                    </div>
                                    
                                </div>


                            </div>
                            <div >
                                <div class="navigation" >
                                    <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                                        {/* <a style={{textDecoration:'none',}} href="https://www.crestbellsupport.com/product-services/"> */}
                                        <Button

                                            sx={{
                                                zIndex: 10000000,
                                                marginTop: '-9%'
                                            }}>

                                            <Typography sx={{

                                                color: "white",
                                                textTransform: "capitalize",
                                                fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                lineHeight: '50px',
                                                '&:hover': {
                                                    background: "none ",
                                                    color: "#6c757d"
                                                }
                                            }
                                            } >Categories </Typography>
                                            <KeyboardArrowDownIcon sx={{ color: 'white' }} />

                                        </Button>
                                    </NavLink>
                                    {/* </a> */}
                                    <div class="navigation-content">
                                        <NavLink to={'/Course'} state={{ category: 'Partnered-organisation' }} style={{ textDecoration: 'none' }}>MRSPTU Courses</NavLink>
                                        <NavLink to={'/Tally'} state={{ category: 'Tally' }} style={{ textDecoration: 'none' }}>Tally Courses</NavLink>
                                        {/* <NavLink to={'/crestbell'} state={{ category: 'crestbell' }} style={{ textDecoration: 'none' }}>Crestbell Courses</NavLink> */}


                                    </div>
                                    
                                </div>


                            </div>
                            <NavLink to={'/Contact'} style={{ textDecoration: 'none' }}>
                                {/* <a style={{textDecoration:'none',}} href='https://www.crestbellsupport.com/contact-us/'> */}
                                <Button>
                                    <Typography sx={{

                                        color: "white",
                                        textTransform: "capitalize",
                                        fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '50px',
                                        '&:hover': {
                                            background: "none  ",
                                            color: "#6c757d"
                                        }
                                    }
                                    } >Contact Us</Typography>
                                </Button>
                            </NavLink>
                            {/* </a> */}
                        </Stack>

                        <Menu
                            className="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",

                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",

                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose1}
                        >
                            {/* <Paper sx={{height:'850px'}}> */}
                            <NavLink to={'/About'} style={{ textDecoration: 'none' }}>
                                {/* <a style={{textDecoration:'none',}} href = "https://www.crestbellsupport.com/about-us/"> */}
                                <MenuItem sx={{ color: '#273773', fontWeight: '600' }}>About Us</MenuItem>
                                {/* </a> */}
                            </NavLink>
                            {/* <NavLink to={'/shabdLangar'} style={{ textDecoration: 'none' }}> */}
                            <a style={{ textDecoration: 'none', }} href="https://www.shabdlangar.org/">
                                <MenuItem sx={{ color: '#273773', fontWeight: '600' }} onClick={handleClose}>ShabdLangar</MenuItem></a>
                            {/* </NavLink> */}
                            <div >
                                <NavLink to={'/Product-services'} style={{ textDecoration: 'none' }}>
                                    {/* <a style={{textDecoration:'none',}} href = "https://www.crestbellsupport.com/about-us/"> */}
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }} onClick={handleMenu}>Product & Services<KeyboardArrowDownIcon sx={{ color: '#273773' }} />
                                    </MenuItem>
                                    {/* </a> */}</NavLink>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorE}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    open={Boolean(anchorE)}
                                    onClose={handleClose}
                                >
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }}> <NavLink to={'/Partnered-organisation'} state={{ category: 'Partnered-organisation' }} style={{ textDecoration: 'none' }}>Partnered Organisation</NavLink></MenuItem>
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }}><NavLink to={'/Keewe-solutions'} state={{ category: 'Keewe-solutions' }} style={{ textDecoration: 'none' }}>KEEWE Solutions</NavLink></MenuItem>

                                </Menu>
                            </div>
                            <div >
                                <NavLink to={'/'} style={{ textDecoration: 'none' }}>
                                    {/* <a style={{textDecoration:'none',}} href = "https://www.crestbellsupport.com/about-us/"> */}
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }} onClick={handleMenu2}>Categories<KeyboardArrowDownIcon sx={{ color: '#273773' }} />
                                    </MenuItem>
                                    {/* </a> */}</NavLink>

                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorE2}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    open={Boolean(anchorE2)}
                                    onClose={handleClose2}
                                >
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }}> <NavLink to={'/Partnered-organisation'} state={{ category: 'Partnered-organisation' }} style={{ textDecoration: 'none' }}>MRSPTU Courses</NavLink></MenuItem>
                                    <MenuItem sx={{ color: '#273773', fontWeight: '600' }}><NavLink to={'/Tally'} state={{ category: 'Tally' }} style={{ textDecoration: 'none' }}>Tally Courses</NavLink></MenuItem>
                                    {/* <MenuItem sx={{ color: '#273773', fontWeight: '600' }}><NavLink to={'/crestbell'} state={{ category: 'crestbell' }} style={{ textDecoration: 'none' }}>Crestbell Courses</NavLink></MenuItem> */}

                                </Menu>
                            </div>
                            <NavLink to={'/Contact'} style={{ textDecoration: 'none' }}>
                                {/* <a style={{textDecoration:'none',}} href = "https://www.crestbellsupport.com/about-us/"> */}
                                <MenuItem sx={{ color: '#273773', fontWeight: '600' }} onClick={handleClose}>Contact Us</MenuItem>
                                {/* </a> */}
                            </NavLink>
                            {/* </Paper> */}



                        </Menu>
                        {/* <div className='but' style={{ flexGrow:'1',display: 'flex', justifyContent: 'center' }}>
                            <NavLink to={'/Support-Center'} style={{ textDecoration: 'none' }}>
                                <Button variant='contained' sx={{ backgroundColor: '#62f4b9', color: '#020139', textTransform: 'capitalize', cursor: 'pointer', '&:hover': { backgroundColor: 'white', color: 'black' } }}>
                                    Apply For ALSC
                                </Button>
                            </NavLink>
                        </div> */}

                    </Toolbar>
                </AppBar>
            </Box>
        </Grid>
    )
}

export default Nav
