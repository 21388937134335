import { Button, Card, Grid, Typography } from '@mui/material'
import React from 'react'
import center from './Images/centre.webp';
import organisation from './Images/organistaion.webp';
import wealth from './Images/wealth.webp'; 
import mrsptu from './Images/mrsptu.png';
import learn from './Images/learn.jpg';
import { hover } from '@testing-library/user-event/dist/hover';
import './Product.css';

const Product = () => {
    // let arr=[
    //     {
    //         heading:'MRSPTU Online',
    //         text:'Admission and logistic support centres for online certification courses in emerging technologies by MRSPTU, Bathinda',
    //     },
    //     {
    //         heading:'Learn Ahead Test Series',
    //         text:'Business Opportunity for Tuition & Learning centres for K-12 classes',
    //     },
    //     {
    //         heading:'Learn Ahead k12',
    //         text:'Business Opportunity for Learning  for Learn Ahead Test Series for 1616 competitive exams',
    //     }
    // ]
    var cardStyle = {
        display: 'block',
    //    margin:"20px",
        transitionDuration: '0.3s',
        height: '305px',
        // shadowColor: "blue",
        // shadowOffset: { width: 0, height: 1 },
        // shadowOpacity: 0.8,
        // shadowRadius: 2,
        // elevation: 5,
    }

  return (
    <Grid container sx={{pt:'5%'}}>

            <Grid item container sx={{ display:{xs:'block', lg:'flex' , sm:'flex'},mb:'2%',justifyContent:'space-between'}}>
                <Grid item xl={1.9} lg={1.5}/>
                <Grid item xl={2.5} lg={2.8} sm={3.5} md={3.5} className='car' sx={{}}>
                    <Card  sx={{borderRadius:'20px',pl:'6%',pr:'6%',pt:'8%',mb:'2%', boxShadow:'0px 0px 10px 0px rgb(0 0 0 / 50%)'}} style={cardStyle}>
                        <img src={mrsptu} height="56px" width="56px"/>
                        <Typography sx={{fontSize: '16px', mb: '6%', pt: '8%', fontFamily:'Roboto' }}>MRSPTU Online</Typography>
                        <Typography sx={{ fontSize: '16px', pb: '8%', fontFamily:'Roboto'}}>
                        Admission and logistic support centres for online certification courses in emerging technologies by MRSPTU, Bathinda
                        </Typography>
                        <a style={{textDecoration:'none'}} href='https://www.mrsptuonline.com/'>
                        <Button variant="contained" sx={{mb:'2%', bgcolor:'#21E6B0',textTransform:'capitalize','&:hover':{bgcolor:'#21E6B0'}}}>Know More</Button></a>
                    </Card>
                </Grid>

                <Grid item xl={2.5} lg={2.8} sm={3.5} md={3.5} className='car' sx={{}}>
                    <Card sx={{borderRadius:'20px',pl:'6%',pr:'6%' ,pt:'8%' ,mb:'2%', boxShadow:'0px 0px 10px 0px rgb(0 0 0 / 50%)'}} style={cardStyle}>
                        <img src={learn} height="26px" width="100px"/>
                        <Typography sx={{ fontSize: '16px', mb: '6%', pt: '8%' , fontFamily:'Roboto'}}>Learn Ahead Test Series</Typography>
                        <Typography sx={{ fontSize: '16px', pb: '8%', fontFamily:'Roboto'}}>
                        Business Opportunity for Tuition & Learning centres for K-12 classes
                        </Typography>
                        <a style={{textDecoration:'none'}} href='https://learnaheadtests.com/'>
                        <Button variant="contained" sx={{mb:'2%', bgcolor:'#21E6B0', mt:'18%',textTransform:'capitalize','&:hover':{bgcolor:'#21E6B0'}}}>Know More</Button></a>
                    </Card>
                </Grid>

                <Grid item xl={2.5} lg={2.8} sm={3.5} md={3.5} className='car' sx={{}}>
                    <Card sx={{ borderRadius:'20px',pl:'6%',pr:'6%',pt:'8%',mb:'2%', boxShadow:'0px 0px 10px 0px rgb(0 0 0 / 50%)'}} style={cardStyle}>
                        <img src={learn} height="26px" width="100px"/>
                        <Typography sx={{ fontSize: '16px', mb: '6%', pt: '8%', fontFamily:'Roboto' }}>Learn Ahead k12</Typography>
                        <Typography sx={{ fontSize: '16px', pb: '8%', fontFamily:'Roboto' }}>
                        Business Opportunity for Learning  for Learn Ahead Test Series for 1616 competitive exams
                        </Typography>
                        <a style={{textDecoration:'none'}} href='https://www.learnahead.in/'>
                        <Button variant="contained" sx={{mb:'4%', bgcolor:'#21E6B0',mt:'10%',textTransform:'capitalize','&:hover':{bgcolor:'#21E6B0'}}}>Know More</Button></a>
                    </Card>
                </Grid>
                <Grid item xl={1.9} lg={1.5}/>
            </Grid>
        </Grid>
  )
}

export default Product
