import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Card, Typography } from '@mui/material';
import P from "../Components/Images/first.jpg";
import './first.css'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function FirstBox() {
  return (

    <Grid container className='blck'>
      <Grid item xl={1.5} lg={1.5} sm={1.5} md={1.5}/>

      <Grid item xl={4} xs={12} lg={4} sm={4} md={4}>
        <Card sx={{ boxShadow: "none", color: "rgb(39 55 115)", textAlign: {xs: 'center'}}}>
          <Typography className="fir" sx={{ fontSize: "60px", fontWeight: 600, mt:'35%'}}>Online Learning 
            <Typography className="fir" sx={{ fontSize: "60px", fontWeight: 600}}>Needs</Typography>
           Offline Support</Typography>
        </Card>
      </Grid>
      <Grid item xl={0.5} lg={0.5}/>
      <Grid item xl={5} lg={5} xs={12} sm={4} md={4} sx={{mb:'2%'}}>
        <Grid item sx={{display: 'flex', justifyContent: 'center'}}>
        <img className="imag" src={P} alt="" style={{ width: "90%", marginTop: "15%" }} />
        </Grid>
        <Typography className="firr" sx={{ fontSize: "20px", fontWeight: 600, textAlign: 'center' }} >Crestbell Admission & Logistic Support Centres </Typography>
        
      </Grid>
      <Grid item xl={1} lg={1} sm={1} md={1}/>
      {/* <Grid item lg={12} sx={{ textAlign: "center" }}>
        <Typography sx={{ fontSize: "25px", fontWeight: 600 }} >
          Technology & Marketing Partner for online certification courses of MRSPTU, Bathinda</Typography>
        <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>Vide E-tender No. MRSPTU/DPR/purchase/2021/424</Typography>
      </Grid> */}
    </Grid>

  );
}
