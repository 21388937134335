import { Grid, Typography, Card, Button } from '@mui/material'
import React from 'react'
import logo from "./Images/logo.png"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './supportcenter.css'
import po from "../Components/Pdf/po.pdf"


const Po = () => {
    const arr = [
        {
            course: 'Schedule a Meeting to become  ',
            course1: ' Partnered Organisation ',
            button: <a href="https://forms.gle/AUJB5VetG7dCWpBC8"><Button variant="contained" sx={{ backgroundColor: '#273773', padding: '2%', pl: '5%', pr: '5%',textTransform:'capitalize','&:hover':{bgcolor:'#273773'} }}>Apply Now <ArrowForwardIcon/>
            </Button></a>
        }
        ,
        {
            course: 'Brochure for',
            course1: ' Partnered Organisation ',
            button: <a href={po}><Button variant="contained" sx={{ backgroundColor: '#273773', padding: '2%', pl: '5%', pr: '5%',textTransform:'capitalize','&:hover':{bgcolor:'#273773'} }}>View <ArrowForwardIcon/>
            </Button></a>

        }
    ]
    return (
        <Grid container>

            <Grid container item  color="white" sx={{ pt: '5%', pb: '5%',pr:'2%',pl:'2%',backgroundImage:'linear-gradient(110deg, #212B51 0%, #33468F 47%)'}}>
                <Grid lg={1.5} md={0.1} sm={0.1}/>
                <Grid item lg={4} md={5.5} sm={5.5} sx={12}>
                   <Grid item className='logo'>
                    <img src={logo} alt="" style={{ width: '220px', height: 'auto' }} />
                    </Grid>
                    <Grid item >
                    <Typography className='text1' sx={{ fontSize: '25px', fontWeight: '600', fontFamily: 'Roboto', lineHeight:'1.2',marginTop:'2%' }}>Crestbell Support Private Limited</Typography>

                    {/* <Typography className='text2' sx={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto', lineHeight: '1.2', pt: '2%' }}>Maharaja Ranjit Singh</Typography>
                    <Typography className='text2' sx={{ fontSize: '18px', fontWeight: '500', fontFamily: 'Roboto', lineHeight: '1.2' }}>Panjab Technical University, BATHINDA</Typography> */}
                </Grid>
                </Grid>
                <Grid lg={3} md={2.4} sm={2.4} xs={12}/>
                <Grid item lg={3} md={4} sm={4} xs={12}>
                    <Typography className='appl' sx={{ fontSize: '50px', fontWeight: '600', fontFamily: 'Roboto',marginTop:'18%' }}>Apply Now</Typography>
                    <hr color="#3feba3" width="35%" />
                </Grid>
                <Grid lg={1.5} md={0.1} sm={0.1}/>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} sx={{ mt: '2%', mb: '7%' }}>
                <Grid lg={4} md={0.3} sm={0.3} xs={0.2}/>
                <Grid item container lg={8} md={11.2} sm={11.2} xs={11.6}>
                    {arr.map((item, index) => {
                        return (


                            <Grid item lg={4} md={6} sm={6} xs={11.6}>

                                <Card className={`hoverimg${index} name`} sx={{ border: '1px solid lightgreen', boxShadow: '0 0 10px #333', borderRadius: '10px', margin: '2%', height: '14rem', textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: '500', fontSize: '24px', mt: '12%', fontFamily: "Roboto" }}>
                                        {item.course}
                                    </Typography>
                                    <Typography sx={{ fontWeight: '500', fontSize: '24px', fontFamily: "Roboto" }}>
                                        {item.course1}
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '500', fontSize: '15px', fontFamily: "Roboto", mt: '2%' }}>
                                        {item.button}
                                    </Typography>
                                </Card>
                            </Grid>

                        )
                    })}
                    <Grid lg={2} xs={0.2}/>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default Po
