import { CheckCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react'
import invesment from './Images/invesment.png';
import './map.css'

const Support1 = () => {
  return (
    <Grid container sx={{pb:'1%', mb:'8%'}}>
        <Grid item xl={1.5} lg={0.8} md={0.2} sm={0.2}/>
            
        <Grid item xl={4} lg={4.7} xs={12} md={5} sm={5} sx={{ml:"6%",pr:'4%'}}>
            <Typography sx={{ fontSize: "25px", fontWeight: 600 , fontFamily:"Roboto", pt:'4%', lineHeight:'1.2'}}>Support Centre Requirements & Benefits</Typography>
                    {/* <hr color="#3feba3" width="25%" align="left"/> */}
                    {/* <Typography sx={{ fontSize: "16px", fontFamily: "Roboto", textAlign: "justify" , paddingTop:"20px" }}></Typography> */}
            <br/>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                Required minimum space/area of 200 Sq.ft.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                    To provide Wi-Fi facility with speed of minimum 100 mbps.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                Should have minimum 10 computers/ laptops of the latest configuration with required software installed.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                    To ensure decent professional ambience with AC.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                    To work as per business model provided by the firm and the associate partner</Typography>
            </Grid>
        </Grid>
                
        <Grid item xl={4} lg={4.5} xs={12} md={4} sm={4} sx={{textAlign:'left', mr:'4%'}}>
            <img className="imaage" src={invesment} style={{ width: "550px" }} />
        </Grid>
        <Grid item xl={2} lg={2} sm={0.2} md={0.2}/>
    </Grid>
  )
}

export default Support1