import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';


export default function APtable() {

    const [data, setData] = useState([])
    useEffect(async () => {

        await axios({
            url: "https://apsc.mrsptuonline.com/supportcentre",
            method: "POST"
        }).then((res) => {
            const arr = res.data.Items
            setData(arr)
        }).catch(err => console.log(err))
    }, [])
  return (
    <div>
        <h1 style={{textAlign:"center",marginTop:'8%'}}>Support Center</h1>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="left">Location</TableCell>
                                                        <TableCell align="center">Address</TableCell>
                                                        {/* <TableCell align="center">Phone Number</TableCell> */}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((row, index) => (
                                                        <TableRow
                                                            key={row.Name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.city}</TableCell>
                                                            <TableCell align="left">{row.address}</TableCell>
                                                            {/* <TableCell align="center">{row.phone}</TableCell> */}


                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
    </div>
  )
}
