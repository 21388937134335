import { Grid, Typography } from '@mui/material'
import React from 'react'
import aboutus from './Images/aboutus.jpg'
import './map.css';

const About1 = () => {
  return (
    <Grid container >
                <Grid item lg={1} xl={1.5} md={0.1} sm={0.1}/>
                <Grid item lg={4.7} xs={12} xl={4} md={6} sm={6} sx={{paddingTop:"20px", textAlign:'left'}}>
                    {/* <Typography sx={{ fontSize: "25px", fontWeight: 600 , fontFamily:"EB Garamond, Sans-serif" }}>“KEEWE Engine for L-Earning Solutions”</Typography> */}
                    {/* <hr color="#3feba3" width="25%" align="left"/> */}
                    <Typography className="typ" sx={{ fontSize: "18px", fontFamily: "Roboto", textAlign: "justify" , paddingTop:"20px",pl:"2%", pr:"2%"}}>
                        
                        With an aim to shape the future of learning, raise the standards of the educational sector, and help businesses and start-ups thrive, we the best Ed-Tech platform in India is providing end-to-end services and facilities to various schools and partnered organizations. So, in order to create the most effective learning environment through the best education systems, CRESTBELL welcomes stakeholders & organizations to collaborate and connect with us.<br/> 
                        
                        <br/>  
                        Furthermore, this integrated Ed-Tech platform – CRESTBELL is known to deliver extensive support through our various partnered organizations which enables you to experience flexible and agile network effects. Also, with the help of the hybrid ecosystem L-Earning, we usher a new era of employability and wealth creation with Learn and Earn methodology. 
                        
                    </Typography>
                </Grid>
                <Grid item lg={4} xl={4} xs={12} md={4} sm={4} sx={{mt:'6%',marginLeft:'2%'}}>
                    <img className='imaage' src={aboutus} style={{ width: "550px" }} />
                </Grid>
                <Grid item lg={2} xl={2} />
            </Grid>

  )
}

export default About1
