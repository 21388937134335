import React from "react";
import { Grid, Typography, Card, Box, Button, Modal } from "@mui/material";
import level1 from "../Components/Images/level1.png";
import level2 from "../Components/Images/level2.png";
import level3 from "../Components/Images/level3.png";
import professional from "../Components/Images/professional.png";
import logotally from "../Components/Images/logotally.png";
import { NavLink } from "react-router-dom";
import Zonal from '../Zonal';
import './Tally.css'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
};
const Tally = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const arr = [
    {
      img: level1,
      title: "TallyEssential Level 1",
      fee: "Coming Soon",
    },
    {
      img: level2,
      title: "TallyEssential Level 2",
      fee: "Coming Soon",
    },
    {
      img: level3,
      title: "TallyEssential Level 3",
      fee: "Coming Soon",
    },
    {
      img: logotally,
      title: "TallyEssential Comprehensive",
      fee: "Coming Soon",
    },
    {
      img: professional,
      title: "TallyProfessional",
      fee: "Coming Soon",
    },
    {
      img: logotally,
      title: "GST using TallyPrime",
      fee: "Coming Soon",
    },
  ];
  return (
    // <G container sx={{ backgroundColor: '#f8f9fb' }}>
    //   <Typography
    //     sx={{
    //       fontSize: "36px",
    //       fontWeight: "500",
    //       fontFamily: "Roboto",
    //       lineHeight: "1.5",
    //       marginBottom: "2%",
    //       textAlign: "center",
    //       marginTop: "6%",
    //     }}
    //   >
    //     Tally Courses
    //   </Typography>
    <Grid
      container
      xs={12}
      lg={12}
      xl={12}
      sm={12}
      md={12}
      sx={{ backgroundColor: "#f8f9fb", marginTop: "4%" }}
    >
      <Grid lg={1} xl={1} sm={0.5} md={0.5} />

      <Grid
        item
        container
        xs={12}
        lg={10}
        xl={10}
        sm={11}
        md={11}
        marginTop="50px"
        marginBottom={"50px"}
      >
        <Grid
          item
          xs={12}
          lg={3}
          xl={4.5}
          sm={6}
          md={6}
          justifyContent="space-between"
          marginTop={"20px"}
        >
          <Typography
            className="course"
            sx={{
              fontSize: "1.65rem",
              fontWeight: "700",
              color: "#283034",
              fontFamily: "Muli,sans-serif",
              paddingLeft: "10px",
            }}
          >
            Tally Courses
          </Typography>
        </Grid>
        <Grid item lg={4} xl={3.5} sm={3.5} md={3.5} xs={8}></Grid>
        <Grid
          item
          xs={3.5}
          lg={2.8}
          xl={3.8}
          sm={2}
          md={2}
          sx={{
            textAlign: "right",
            marginTop: "20px",
          }}
        >
          {/* <NavLink to={''} style={{ textDecoration: 'none', display: 'flex' }}> */}

          {/* </NavLink> */}
        </Grid>
        {arr.map((item) => {
          return (
            <Grid
              item
              xs={12}
              lg={3}
              xl={3}
              sm={6}
              md={6}
              marginTop="40px"
              padding={"12px"}
            >
              {/* <NavLink
                                    to={'/Tallypage'} state={{ courseId: item.courseId }}
                                    style={{ textDecoration: 'none' }}> */}

              <Box
                onClick={handleOpen}
                sx={{
                  height: "360px",
                  width: "100%",
                 
                  boxShadow: "0 50px 100px -20px rgb(50 50 93 / 25%)",
                  textAlign: "left",
                  borderRadius: "10px",
                  cursor: "pointer",
                  "&:hover": { boxShadow: "0 0 0 0", border: "1px solid #ddd", },
                }}
              >
                <img
                  src={item.img}
                  style={{
                    width: "100%",
                    height: "210px",
                    borderRadius: "5px",
                  }}
                />
                <Box>
                  <Grid marginTop={"8px"} marginLeft="5px" display="flex">
                    <Box
                      sx={{
                        margin: "5px",
                        width: "auto",
                        fontSize: ".915rem",
                        borderRadius: "4px",
                        backgroundColor: "#f0f1f2",
                        height: "25px",
                        textAlign: "center",
                        color: "#333",
                      }}
                    >
                      Languages:
                    </Box>
                    <Box
                      sx={{
                        margin: "5px",
                        width: "auto",
                        fontSize: ".915rem",
                        borderRadius: "4px",
                        backgroundColor: "#f0f1f2",
                        height: "25px",
                        textAlign: "center",
                        color: "#333",
                      }}
                    >
                      English
                    </Box>
                    <Box
                      sx={{
                        margin: "5px",
                        width: "auto",
                        fontSize: ".915rem",
                        borderRadius: "4px",
                        backgroundColor: "#f0f1f2",
                        height: "25px",
                        textAlign: "center",
                        color: "#333",
                      }}
                    ></Box>
                  </Grid>

                  <Grid marginTop={"8px"} marginLeft="10px">
                    <Typography
                      sx={{
                        fontSize: "1.15rem",
                        color: "#283034",
                        fontWeight: "700",
                        fontFamily: "Muli,sans-serif",
                      }}
                    >
                      {item.title}
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: "1.05rem",
                        marginTop: "8px",
                        color: "#888",
                        fontWeight: "400",
                        fontFamily: "Muli,sans-serif",
                      }}
                    >
                      4.5 (26 Ratings)
                    </Typography> */}
                  </Grid>
                  {/* <Grid marginTop={'8px'} marginLeft='10px' display={'flex'}>
                                                <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>Fee : </Typography>
                                                <Typography sx={{ fontSize: '1.05rem', marginTop: '2px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif',textDecoration:'line-through',marginLeft:'4px' }}>₹ 5000</Typography>   

                                            </Grid> */}
                </Box>
              
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "2%", marginTop: "4%" }}
                  >
                    Enroll Now
                  </Button>
                
               
              </Box>
              {/* </NavLink> */}
            </Grid>
          );
        })}
      </Grid> 
       <Modal className="mod1"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflowY: "scroll" }}
                  >
                    <Box className='mod' sx={style}>
                    <Grid container sx={{justifyContent:'end'}}>
                    
                    <button onClick={handleClose} style={{height:'28px'}}>x</button>
                </Grid>
                <div className="cad"> <Zonal/></div>
                    
                     <Typography textAlign={'center'}><h4>Click on cards to view support centre list</h4></Typography>
                    </Box>
                  </Modal>
    </Grid>
  );
};

export default Tally;
