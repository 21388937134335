import { Grid, Typography, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import certificate from '../Images/certificate.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const Certificate = ({ courseArray }) => {
  const [show, setShow] = useState(1)
  const [work, setwork] = useState([])
  useEffect(() => {
    setwork(courseArray.courseDetails.workOpportunities)
  }, [])


  return (
    <Grid container lg={12} xl={12} md={12} sm={12}>
      <Grid lg={0.1} xl={0.1} md={0.1} sm={0.1} />
      <Grid container item lg={11.4} xl={11.4} sm={11.4} md={11.4}>
        <Grid item xl={4.4} lg={4.4} sm={4.4} md={4.4} sx={{ display: 'block' }}>
          <Card onClick={() => {
            setShow(1)
          }} sx={{ padding: '26px 30px', boxShadow: '0px 0px 0px 0.3px', borderRadius: '5px 5px 0px 0px', backgroundColor: show == 1 ? "#f0f1f3" : "white" }}>
            <Typography sx={{ fontWeight: '700', fontFamily: 'Roboto', fontSize: '1.125rem', marginBottom: '8px' }}>Certification</Typography>
            <Typography sx={{ color: '#888', fontWeight: '300', fontFamily: 'Roboto', fontSize: '0.95rem', lineHeight: 1.3, marginBottom: '8px' }}>At the End, Certificates will be issued to all the successful candidates who secure a minimum of 50% in the Final Online Assessment.</Typography>
          </Card>

          <Card onClick={() => {
            setShow(2)
          }} sx={{ padding: '26px 30px', boxShadow: '0px 0px 0px 0.3px', borderRadius: '0px 0px 0px 0px', backgroundColor: show == 2 ? "#f0f1f3" : "white" }}>
            <Typography sx={{ fontWeight: '700', fontFamily: 'Roboto', fontSize: '1.125rem', marginBottom: '8px' }}>Work Opportunities</Typography>
            <Typography sx={{ color: '#888', color: '#888', fontWeight: '300', fontFamily: 'Roboto', fontSize: '0.95rem', lineHeight: 1.3, marginBottom: '8px' }}>Self Employment After Completion of Course</Typography>
          </Card>

          <Card onClick={() => {
            setShow(3)
          }} sx={{ padding: '26px 30px', boxShadow: '0px 0px 0px 0.3px', borderRadius: '0px 0px 5px 5px', backgroundColor: show == 3 ? "#f0f1f3" : "white" }}>
            <Typography sx={{ fontWeight: '700', fontFamily: 'Roboto', fontSize: '1.125rem', marginBottom: '8px' }}>FAQS</Typography>
            <Typography sx={{ color: '#888', color: '#888', fontWeight: '300', fontFamily: 'Roboto', fontSize: '0.95rem', lineHeight: 1.3, marginBottom: '8px' }}>Frequently Asked Questions</Typography>
          </Card>
        </Grid>
        <Grid lg={0.3} xl={0.3} sm={0.3} md={0.3}/>
        {show == 1 && <Grid item lg={5.5} xl={5.5} sm={5.5} md={5.5} xs={8.5}>
          <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, fontFamily: 'Roboto' }}>Certificate of MRSPTU</Typography>
          <img src={certificate} style={{ width: "130%", height: 'auto' }} />
        </Grid>}

        {show == 2 && <Grid item lg={5.5} xl={5.5} sm={5.5} md={5.5}>
          <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, fontFamily: 'Roboto', mb: '15px' }}>Wok Opportunities</Typography>
          <Grid item sx={{ color: '#283034', fontWeight: '300' }}>
            <ul>
              {work.map((item) => {
                return (
                  <li key={item}>{item}</li>)
              })}
            </ul>
          </Grid>
        </Grid>}


        {show == 3 && <Grid item lg={6.7} xl={6.7} sm={6.7} md={6.7} sx={{ fontWeight: 400, fontSize: '1rem', fontFamily: 'Roboto' }}>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Question 1: Are these online programs easily accessible?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: Online courses transcend time,
                geographic locations and other barriers to
                higher education. This can be helpful to those
                who work full time, live in remote regions or are
                constantly on the move.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 2: When can I take exams?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: The University does not have any
                set schedule for the exams. It is a
                self-paced course.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 3: Will a certificate be provided?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: A certificate will be issued by
                the university to all the successful
                candidates who secure a minimum of 50% in
                the final online assessment.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 4: What is the overall structure of the COURSE ? What are the Levels?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: The Course is split into three
                levels:-Module, Topics and Sub-Topics</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 5: What is the language of instruction for these courses? Are they available in other regional languages?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: All our courses are taught in a
                mix of English and Hindi. Hence, English
                knowledge will not be a barrier to
                participate in the program.</Typography>
            </AccordionDetails>
          </Accordion>


        </Grid>}
      </Grid>

      <Grid lg={0.5} xl={0.5} sm={0.5} md={0.5}/>
      {/* </Grid>
        <Grid lg={0.3} xl={0.3} />
        {show == 1 && <Grid item lg={5.3} xl={5.3}>
          <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, fontFamily: 'Roboto' }}>Certificate of MRSPTU</Typography>
          <img src={certificate} style={{ width: "130%", height: 'auto' }} />
        </Grid>}

        {show == 2 && <Grid item lg={6.4} xl={6.4}>
          <Typography sx={{ fontSize: '1.875rem', fontWeight: 700, fontFamily: 'Roboto', mb: '15px' }}>Wok Opportunities</Typography>
          <Grid item sx={{ color: '#283034', fontWeight: '300' }}>
            <Typography>Junior Programmer</Typography>
            <Typography>Senior Programmer</Typography>
            <Typography>Software Developer</Typography>
            <Typography>Quality Analyst</Typography>
            <Typography>Game programmer</Typography>
            <Typography>Software developer engineer</Typography>
            <Typography>C analyst</Typography>
            <Typography>Programming architect</Typography>
            <Typography>UNIX shell scripting</Typography>
            <Typography>Backend developer</Typography>
            <Typography>Embedded engineer</Typography>
            <Typography>Database developer</Typography>
          </Grid>
        </Grid>}


        {show == 3 && <Grid item lg={6.8} xl={6.8} sx={{ fontWeight: 400, fontSize: '1rem', fontFamily: 'Roboto' }}>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Question 1: Are these online programs easily accessible?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: Online courses transcend time,
                geographic locations and other barriers to
                higher education. This can be helpful to those
                who work full time, live in remote regions or are
                constantly on the move.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 2: When can I take exams?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: The University does not have any
                set schedule for the exams. It is a
                self-paced course.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 3: Will a certificate be provided?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: A certificate will be issued by
                the university to all the successful
                candidates who secure a minimum of 50% in
                the final online assessment.</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 4: What is the overall structure of the COURSE ? What are the Levels?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: The Course is split into three
                levels:-Module, Topics and Sub-Topics</Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Question 5: What is the language of instruction for these courses? Are they available in other regional languages?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Answer: All our courses are taught in a
                mix of English and Hindi. Hence, English
                knowledge will not be a barrier to
                participate in the program.</Typography>
            </AccordionDetails>
          </Accordion>


        </Grid>}
      </Grid>

      <Grid lg={0.7} xl={0.7} /> */}
    </Grid>
  )
}

export default Certificate
