import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Card, Typography } from '@mui/material';
import P from "../Components/Images/bg.png";
import Mrsptu from "./Images/mrsptu.png";
import cb from "./Images/Asset.png";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function SecondHome() {
    return (
        <>
        <Grid container sx={{ padding: "20px", backgroundImage: `url(${P})`, backgroundColor: "#283034", display:{xs:'none',lg:'flex'} }}>
            <Grid xl={1} lg={0.5}/>
            <Grid item xl={10} lg={11} xs={12} sx={{ padding: "40px 0px 40px 0px" }}>
                <Card sx={{ padding: "35px", boxShadow: "none", color: "rgb(39 55 115)" , position:"relative" , height:"100px"}}>
                    {/* <Typography sx={{ fontSize: "35px", fontWeight: 600 }}>Online learning  Needs Offline Support</Typography>
          <Typography sx={{ fontSize: "35px", fontWeight: 600 }} >Crestbell Admission & Logistic Support Centres </Typography> */}
                    <Typography sx={{ fontSize: "26px", fontWeight: 600, paddingLeft: "2rem" }} >
                        Technology & Marketing Partner for online certification courses of   MRSPTU, Bathinda</Typography>
                    <Grid item container sx={{ display: "flex"  }}>
                        <Grid item xl={6} lg={6} xs={12} sx={{position:"absolute" , top:"95px" }}>
                            <Typography sx={{ fontSize: "20px", fontWeight: 600, paddingLeft: "2rem" }}>Vide E-tender No. MRSPTU/DPR/purchase/2021/424</Typography>
                        </Grid>
                        <Grid item xl={4} lg={4} sx={{ textAlign: "end" , position:"absolute" , right:"220px" }}>
                            <img src={Mrsptu} style={{ width: "80px" }} />
                        </Grid>
                        <Grid item xl={1} lg={1} sx={{ textAlign: "left" , position:"absolute" , right:"140px" }}>
                            <img src={cb} style={{ width: "60px"  }} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid xl={1} lg={1}/>
        </Grid>

          <Grid container xs={12} sx={{padding: "10px", backgroundImage: `url(${P})`, backgroundColor: "#283034", display:{xs:'flex',lg:'none'}}}>
            <Grid item >
            <Card sx={{ padding: "15px", boxShadow: "none", color: "rgb(39 55 115)" , position:"relative" , height:"230px", justifyContent:'center'}}>
            <Typography sx={{ fontSize: "20px", fontWeight: 600}} >
                        Technology & Marketing Partner for online certification courses of MRSPTU, Bathinda</Typography>
            {/* <Grid item xl={6} lg={6} xs={12} sx={{position:"absolute" , top:"95px" }}> */}
                <Typography sx={{ fontSize: "18px", fontWeight: 500, paddingTop:'2%', paddingBottom:'2%'}}>Vide E-tender No. MRSPTU/DPR/purchase/2021/424</Typography>
            {/* </Grid> */}
            <img src={Mrsptu} style={{ width: "80px",paddingBottom:'2%' }} />
            <img src={cb} style={{ width: "60px",paddingBottom:'2%' }} />
            </Card>
            </Grid>
          </Grid>
        </>
    );
}
