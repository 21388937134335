import { Grid, Typography } from '@mui/material'
import React from 'react'

function Finalexam() {
  return (
    <Grid sx={{mt:'12%',mb:'12%',mr:'10%'}}>
        <Typography sx={{fontFamily:'Roboto',fontSize:'26px', fontWeight:'500',ml:'5%'}}>About Final Exam</Typography>
        <ul>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'17px'}}>Course Based Mcq Questions.</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'17px'}}>Exam open after 80% Completion of course.</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'17px'}}>For Certificate minimum 50% Score Requried.</Typography></li>
        </ul>
    </Grid>
  )
}

export default Finalexam
