import React from 'react';
import Firstcomp from '../Components/Firstcomp';
import Contact from '../Components/Contact';

const ContactUs = () => {
  return (
    <div>
      <Firstcomp/>
      <Contact/>
      
    </div>
  )
}

export default ContactUs
