import { Grid, Typography, CardMedia, Card } from '@mui/material'
import React from 'react'
import join from './joinus.png'
import SearchIcon from '@mui/icons-material/Search';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Groups2Icon from '@mui/icons-material/Groups2';
import LanguageIcon from '@mui/icons-material/Language';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import './map.css';
// import Icon from '@mui/material/Icon';

const Joinus = () => {
    // const Icons = { "SearchIcon": SearchIcon }
    const arr = [
        {
            icon: <SearchIcon sx={{fontSize:'4rem'}}/>,
            course: 'Research',
            Description: ' Assign research work of your innovative product development to our skilled students.'
        },
        {
            icon: <LightbulbIcon sx={{fontSize:'4rem'}}/>,
            course: 'Incubation facilities',
            Description: ' Display ideas & innovations, get funds & your product purchases by partnered firms.'
        },
        {
            icon: <ImageSearchIcon sx={{fontSize:'4rem'}}/>,
            course: 'CMS',
            Description: ' Develop courses, upload, and sell – become a revenue-sharing partner.'
        },
        {
            icon: <Groups2Icon sx={{fontSize:'4rem'}}/>,
            course: 'Job portal',
            Description: ' Post jobs and get access to relevant skilled students.'
        },
        {
            icon: <LanguageIcon sx={{fontSize:'4rem'}}/>,
            course: 'Networking media',
            Description: ' Sell your products on the e-commerce portal or advertise for free.'
        },
        {
            icon: <VpnLockIcon sx={{fontSize:'4rem'}}/>,
            course: 'Freelance Work',
            Description: ' With an array of businesses, organizations, institutions connected with CRESTBELL.'
        }
    ]
    return (
        <Grid container xl={12} lg={12} xs={12}  md={12} sm={12}  sx={{ mt: '3%' }}>
            {/* <Grid container item lg={6} xs={12} sx={{ justifyContent: 'right', display:'flex', justifyContent: {xs: 'center'}}}>
        <Grid container xl={12} lg={12} xs={12} sx={{ mt: '3%' }}>
            <Grid container item xl={6} lg={6} xs={12} sx={{ justifyContent: 'right', display:'flex', justifyContent: {xs: 'center'}}}>
                <CardMedia
                    component="img"
                    height="350"
                    style={{ width: "auto", paddingLeft: '15%', boxSizing:"border-box" }}
                    image={join}
                    alt="pic"
                />
            </Grid> */}
            {/* <Grid item lg={4} sx={{ ml: '1%' }}>
            </Grid>
            <Grid item xl={4} lg={4} sx={{ ml: '1%' }}>
                <Typography sx={{ fontSize: '30px', fontWeight: 600 }}>“Join Us as a Partnered Organization”</Typography>
                <Typography sx={{ fontWeight: 'bolder', fontSize: '17px', mt: '3%' }}>Partner with Us and Discover the New Talents. </Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '17px', mt: '3%' }}>Among the diverse range of partnered organizations including schools, universities, tech-enabled start-ups, organizations, etc. collaborate with firms and overcome your business challenges. CRESTBELL – the best Ed-tech platform in India allows you as a partnered organization to enjoy multiple facilities, provide employment to the students, and boost your business.</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '17px' }}><br />Therefore, with a number of verticals and business sectors being partnered with CRESTBELL, this digitized Ed-Tech platform provides a great benefit to our partners as well as students. </Typography>
            </Grid> */}
            <Grid container item sx={{pl:'2%',pr:'2%'}}>
                <Grid item lg={1} xl={1.5} />
                <Grid item xl={4.8} lg={5} xs={12} md={6} sm={6} >
                    <img className="imaage" src={join} style={{ paddingTop:'6%',width: "550px" }} />
                </Grid>
                {/* <Grid item md={0.5} sm={0.5} lg={0} xl={0} /> */}
                
                <Grid item xl={4.5} className="asdf" lg={5} md={5.3} sm={5.3} xs={12} sx={{paddingTop:"20px"}}>
                    <Typography sx={{ fontSize: "27px", fontWeight: 600 , fontFamily:"Roboto" }}>“Join Us as a Partnered Organization”</Typography>
                    <Typography sx={{ fontSize: "18px", fontWeight: 'bolder' , fontFamily:"Roboto", pt: '3%' }}>Partner with Us and Discover the New Talents.</Typography>
                    <Typography sx={{ fontSize: "17.5px", fontWeight: 400 , fontFamily: "Roboto", textAlign: "justify" , paddingTop:"3%", lineHeight: '1.3' }}> Among the diverse range of partnered organizations including schools, universities, tech-enabled start-ups, organizations, etc. collaborate with firms and overcome your business challenges. CRESTBELL – the best Ed-tech platform in India allows you as a partnered organization to enjoy multiple facilities, provide employment to the students, and boost your business. </Typography>
                    <Typography sx={{ fontSize: "17.5px", fontWeight: 400 , fontFamily: "Roboto", textAlign: "justify" , paddingTop:"3%", lineHeight: '1.3' }}>Therefore, with a number of verticals and business sectors being partnered with CRESTBELL, this digitized Ed-Tech platform provides a great benefit to our partners as well as students.</Typography>
                </Grid>
                
                <Grid item lg={2} md={0.2} sm={0.2} />
            </Grid>
            <Grid xl={2} lg={2} />
            <Grid container item xl={12} lg={12} sx={{ justifyContent: 'center', mt: '3%', mb:'2%' }}>
                <Typography sx={{ fontSize: '2rem', fontWeight: 450, fontFamily: "Roboto" }}>Why Partners with </Typography>
                <Typography sx={{ fontWeight: 650, fontSize: '2rem', color: '#273773', ml: '0.5%', fontFamily: "Roboto" }}> CRESTBELL?</Typography>
                </Grid>
                <hr color="#3feba3" width="20%"/>
            <Grid container xl={12} xs={12} lg={12}  md={12} sm={12} sx={{mt:'1%'}}>
                <Grid xl={1.5} lg={1.5} xs={0.3}  md={0.2} sm={0.2} />
                <Grid item container xl={9} lg={9} xs={11.4} md={11.6} sm={11.6} >
                    {arr.map((item) => {
                        // const Icon = Icons[item.icon]
                        // console.log(Icon)
                        return (


                            <Grid item xl={4} lg={4}  md={4} sm={4} >

                                <Card sx={{ border: '1px solid black', boxShadow: '0 0 10px rgb(0 0 0 / 45%)', borderRadius: '10px', margin: '3%', height: '90%' }}>
                                    <Grid item sx={{mt: '5%', color: '#273773', ml: '5%'}}>
                                    {/* <SearchIcon /> */}
                                    {item.icon}
                                    </Grid>
                                    <Typography sx={{ fontWeight: '400', fontSize: '30px', mt: '7%', pl: '7%', fontFamily: "Roboto" }}>
                                        {item.course}
                                    </Typography>
                                    <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', fontSize: '17px', pl: '7%', pt: '5%' , pb: '10%', fontFamily: "Roboto"}}>
                                        {item.Description}
                                    </Typography>
                                </Card>
                            </Grid>

                        )
                    })}
                </Grid>
                <Grid xl={1.5} lg={1.5} xs={0.3}  md={0.2} sm={0.2} />
            </Grid>
        </Grid>
    )
}

export default Joinus
