import { CheckCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react'
import roles from './Images/roles.jpg';
import './map.css'

const Support2 = () => {
  return (
    <Grid container sx={{pb:'1%', mb:'10%'}}>
        <Grid item xl={1.5} lg={1.2} sm={0.2} md={0.2}/>
        
        <Grid item xl={4} lg={4} xs={12} md={4} sm={4} sx={{textAlign:'left', mr:'4%', pt:'3%'}}>
            <img className="imaage" src={roles} style={{ width: "500px" }} />
        </Grid>
        <Grid item sm={0.9} md={0.9} lg={0.2}/>
        <Grid item xl={4} lg={4.7} xs={12} md={5.7} sm={5.7} sx={{ml:"2%",pr:'2%'}}>
            <Typography sx={{ fontSize: "25px", fontWeight: 600 , fontFamily:"Roboto",lineHeight:'1.2'}}>Roles & Responsibilities of Associate Partner</Typography>
                    <hr color="#3feba3" width="25%" align="left"/>
                    {/* <Typography sx={{ fontSize: "16px", fontFamily: "Roboto", textAlign: "justify" , paddingTop:"20px" }}></Typography> */}
            <br/>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To accelerate the growth rate of the establishment of the support centres in the zone.
                </Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To do hybrid marketing (offline & online) at the selected zone.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To provide comprehensive support to all the support centres of the zone..</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                    To act as a link between firm and the zonal support centres.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To devise the plans and strategies for mobilizing the admissions to various courses.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To frame marketing strategies and supervise operations of the support centres.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To give feedback to the firm regarding the market trends.</Typography>
            </Grid>
            <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                <Grid item sx={{mr:'1%', color:"#273773"}}><CheckCircle/></Grid>
                <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify"}}>
                To coordinate weekly / monthly meetings of the support centres.</Typography>
            </Grid>
        </Grid>
                
        
        <Grid item xl={1} lg={1}/>
    </Grid>
  )
}

export default Support2