import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel, Typography, } from '@mui/material';
import React from 'react';
import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import jsonList from './centers.json';
import { Button } from 'reactstrap';




export default function Supportcenter({id}) {
	// const {add,setAdd}=useContext(Address);
	const [add, setAdd] = useState("")
	const [sc, setSc] = useState(0)
	const [cityArr, setCityArr] = React.useState(['none']);
	const [data, setData] = useState({})


	const handleChange = (e) => {
		let cityData = e.target.value.split(" ")
		let city1 = cityData
		// setAdd({...add,city:city1})
		setData({ ...data, userIdSC: city1[0], userIdAP: city1[1] })
	}


	return (
		<Grid container >
			<Grid item xs={12} lg={12} marginTop="2%">

				<Grid item xs={12} lg={12}> 	<Typography sx={{fontWeight:600 , fontSize:"20px"}}>Please Select Your Support Center</Typography></Grid>
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				>
					<InputLabel>State</InputLabel>
					<Select label="State" sx={{ width: '99%', height: '50px' }} value={add.country} onChange={(e) => setAdd({ ...add, country: e.target.value })}>
						{jsonList.Countries.map((country, countryIndex) => {
							return (
								<MenuItem
									onClick={() => {
										setSc(countryIndex)
									}}
									value={country.CountryName}
									key={country.CountryName}
								>
									{country.CountryName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} lg={12} marginTop="2%"  marginRight="3px">
				<Grid item xs={12}></Grid>
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				>
					<InputLabel>Zone</InputLabel>
					<Select label="Zone" sx={{ width: '99%', height: '50px' }}
						value={add.state}
						onChange={(e) => setAdd({ ...add, state: e.target.value })}>
						{jsonList.Countries[sc].States.map((state, stateIndex) => {
							return (
								<MenuItem
									onClick={() => {
										setCityArr(jsonList.Countries[sc].States[stateIndex].Cities);
										// setState(stateArr[stateArrIndex].StateName)
									}}
									value={state.StateName}
									key={state.StateName}
								>
									{state.StateName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} lg={12} marginTop="2%" >
				<FormControl
					sx={{
						marginBottom: '3%'
					}}
					fullWidth
				>
					<InputLabel>Support Center</InputLabel>
					<Select	
						label="Support Center"
						sx={{ width: '99%', height: '50px' }}
						// value={add.city}
						onChange={(e) => {
							handleChange(e);

							// setCity(e.target.value);
						}}
					>
						{cityArr.map((city, cityIndex) => {
							return (
								<MenuItem value={city.UserIdSC + " " + city.UserIdAP} key={city.Address}>
									{city.Address}
								</MenuItem>
							);
						})}
					</Select>
					
				</FormControl>
			</Grid>
			<Grid lg={6} xs={12}>
			<a href={
                        "https://lms.mrsptuonline.com?courseId=" +
                        id+"="+data.userIdAP+"="+data.userIdSC
                      }>
				<Button disabled={!(data.userIdAP && data.userIdSC)} variant="contained" style={{ backgroundColor:data.userIdAP && data.userIdSC?"#273773":"grey" , color:"white" , height:"40px" , width:"200px" , cursor:data.userIdAP && data.userIdSC?"pointer":""}}>Submit</Button>
				</a>
				</Grid>
		</Grid>
	);
}

