import React from 'react';
import Product1 from '../Components/Product1';
import Support1 from '../Components/Support1';
import Support2 from '../Components/Support2';
import SupportTry from '../Components/SupportTry';

const ProductServices = () => {
  return (
    <>
    
    <SupportTry/>
      <Product1 />
      <Support1 />
      <Support2 />
    </>
  )
}

export default ProductServices