import React from 'react';
import { Box, Typography, Card } from '@mui/material';

const About = ({ courseArray, teacher }) => {
    return (
        <Box>
            <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem', marginTop: '1rem' }}>
                Requirements
            </Typography>

            <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.125rem', fontWeight: '400', color: '#283034', marginBottom: '0.5rem' }}>
                {courseArray.courseDetails.requirement}
            </Typography>

            <Card sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: "25px 0px 0px 0px", padding: '30px 25px', borderRadius: '10px' }}>
                <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                    ABOUT THE AUTHOR
                </Typography>
                {teacher != undefined ?
                    <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                        {teacher.Description}
                    </Typography>
                    : null
                }
            </Card>
        </Box>

    )
}

export default About