import { Grid, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal';
import { Card, Typography, Box, rgbToHex } from '@mui/material';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const style1 = {
  position: 'absolute',

  left: '5%',
  // transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Nav2 = () => {

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);

  useEffect(async () => {

    await axios({
        url: "https://apsc.mrsptuonline.com/supportcentre",
        method: "POST"
    }).then((res) => {
        const arr = res.data.Items
        setData(arr)
    }).catch(err => console.log(err))
}, [])

  return (
    <Grid container sx={{justifyContent:'center'}}>
      <Button disabled variant='contained' onClick={()=>{
        setOpen(true)
      }} sx={{position:"relative",marginBottom:'2%',marginLeft:'2%', borderRadius:'5px 5px 5px 5px',marginTop:'6%',backgroundColor: '#ff1f59',fontSize:'16px',fontWeight:'600',color:'white', textTransform:'capitalize',cursor:'pointer','&:hover':{backgroundColor:'white',color:'black'}}}>Logistic Support</Button>
      <Button onClick={()=>{
        setOpen1(true)
      }} variant='contained' sx={{marginBottom:'2%', marginLeft:'2%',borderRadius:'5px 5px 5px 5px',marginTop:'6%',backgroundColor: '#ff1f59',fontSize:'16px',fontWeight:'600',color:'white', textTransform:'capitalize',cursor:'pointer','&:hover':{backgroundColor:'white',color:'black'}}}>Support Center List</Button>

      <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* <Supportcenter id={courseArray.id} /> */}
              </Box>
            </Modal>

            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ position: "relative", height: "100vh", overflowY: "scroll" }}
            >
              <Box sx={style1}>
                {/* <Supportcenter id={courseArray.id} /> */}
                <h4 >Support Center</h4>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="left">Location</TableCell>
                                                        <TableCell align="center">Address</TableCell>
                                                        <TableCell align="center">Phone Number</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((row, index) => (
                                                        <TableRow
                                                            key={row.Name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.city}</TableCell>
                                                            <TableCell align="left">{row.address}</TableCell>
                                                            <TableCell align="center">{row.phone}</TableCell>


                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
              </Box>
            </Modal>

    </Grid>
  )
}

export default Nav2
