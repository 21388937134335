import React from 'react';
import { Box, Typography, Card } from '@mui/material';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '5px', transform: 'scale(1.5)' }}
  >
    •
  </Box>
);
const Sql = ({ courseArray, teacher }) => {
  return (
    <Box>
      <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '2.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '0.5rem' }}>
        {courseArray.courseDetails.title}
      </Typography>
      <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.125rem', fontWeight: '400', color: '#283034', marginBottom: '0.5rem' }}>
        {courseArray.courseDetails.description}
      </Typography>
      {teacher != undefined
        ?
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1rem', fontWeight: '400', color: '#888', marginBottom: '0.5rem' }}>
          Created by: {teacher.Firstname}
        </Typography>
        :
        null
      }
      <Card sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: '20px 0px 0px 0px', padding: '30px 25px', borderRadius: '10px' }}>
        <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
          What you'll learn
        </Typography>
        {courseArray.courseDetails.skills.map((item, index) => {
          return(<div style={{display:'flex'}}>
            <Typography>
            {bull} 
            </Typography> 
          <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px', ml:'1.5%' }}>
              {item}
          </Typography></div>)
        })}
        {/* <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}How to create database with SQL.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Learn how to code in SQL.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Handle SQL joins.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontWeight: '400', color: '#283034', lineHeight: '1', marginBottom: '12px' }}>
          {bull}Learn to insert, update and delete records from the database.
        </Typography> */}
      </Card>
    </Box>

  )
}

export default Sql