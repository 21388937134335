import React from 'react'
import Keewecomp from '../Components/Keewecomp'
import Keewecomp1 from '../Components/Keewecomp1'
import Keewecomp2 from '../Components/Keewecomp2'

const KeeweSolutions = () => {
  return (
    <div>
      <Keewecomp/>
      <Keewecomp1/>
      <Keewecomp2/>
    </div>
  )
}

export default KeeweSolutions
