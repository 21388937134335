import React, { Fragment, useContext, useEffect, useState } from "react";
import { Grid, Box } from '@mui/material';
import ComingSoon from './ComingSoon';
import Sql from './Sql';
import About from './About';
import CourseContent from './CourseContent';
import Comments from './Comments';
import Rating from './Rating1';
import Certificate from "./Certificate";
import Finalexam from "./Finalexam";
import Sidepaper from './Sidepaper';


const All = ({ courseArray, teacher}) => {


  return courseArray != null ?  (
    <>

      <Grid container padding="20px" >
        <Grid item lg={0.5} md={2} sm={2}></Grid>
        <Grid item xs={12} lg={4} md={8} sm={8} padding={'1%'}>
          <ComingSoon courseArray={courseArray}/>
          <Finalexam/>
          <Sidepaper/>
        </Grid>
        <Grid item xs={12} lg={6.5} md={12} sm={12} padding={'0.5%'}>

          <Sql teacher={teacher} courseArray={courseArray} />
          <About teacher={teacher} courseArray={courseArray} />
          <CourseContent courseArray={courseArray}/>
          <Rating />
          <Comments />
           </Grid>
        <Grid item lg={0.5} md={1} sm={1} />
        <Certificate courseArray={courseArray}/>
       
      </Grid>

    </>
  ): null;
}

export default All