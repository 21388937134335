import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { KeyboardDoubleArrowRight } from '@mui/icons-material';


export default function Keewecomp2() {
    return (
        <Box sx={{ flexGrow: 1 , pl:'2%',pr:'3%'}}>
            <Grid container >
                <Grid lg={1} xl={1.5} />
                <Grid item lg={10} xl={9} >

                    <Grid item sx={{paddingTop:"10%"}}>
                    <Typography sx={{ fontSize: "25px", fontWeight: 600 , fontFamily:"EB Garamond, Sans-serif",textAlign:'left' }}>KEEWE Objectives:</Typography>
                    <hr color="#3feba3" width="10%" align="left"/>
                    </Grid>
                    <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                        <Grid item sx={{mr:'1%'}}><KeyboardDoubleArrowRight sx={{color:'#273773'}}/></Grid>
                        <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", lineHeight:'1.5', pb:'1%'}}>
                        Help various universities and their affiliate colleges and institutions to digitize their operations through various advanced and latest technological facilities.</Typography>
                    </Grid>
                    <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                        <Grid item sx={{mr:'1%'}}><KeyboardDoubleArrowRight sx={{color:'#273773'}}/></Grid>
                        <Typography sx={{ fontSize: "17px",  fontWeight: 500,fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", lineHeight:'1.5', pb:'1%'}}>Through this organized networking platform – CRESTBELL, one can learn, create courses, and provide knowledge to the students.</Typography>
                    </Grid>
                    <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                        <Grid item sx={{mr:'1%'}}><KeyboardDoubleArrowRight sx={{color:'#273773'}}/></Grid>
                        <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", lineHeight:'1.5', pb:'1%'}}>
                        Create training sessions and programs as per the new job demands and educate the future personnel for better employment.</Typography>
                    </Grid>
                    <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                        <Grid item sx={{mr:'1%'}}><KeyboardDoubleArrowRight sx={{color:'#273773'}}/></Grid>
                        <Typography sx={{ fontSize: "17px", fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", lineHeight:'1.5', pb:'1%'}}>
                        Put your idea into practice and introduce it to the real world, get funding for your innovative ideas, and fulfill your dreams of entrepreneurship.</Typography>
                    </Grid>
                    <Grid item sx={{display:'flex', paddingTop: "6px" }}>
                        <Grid item sx={{mr:'1%'}}><KeyboardDoubleArrowRight sx={{color:'#273773'}}/></Grid>
                        <Typography sx={{ fontSize: "17px",  fontWeight: 500, fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", pb:'8%', lineHeight:'1.5'}}>
                        Advertise and sell courses, and thus, become a revenue-sharing partner.</Typography>
                    </Grid>
                </Grid>
                <Grid lg={1} xl={1.5} />
            </Grid>
        </Box>
    );
}
