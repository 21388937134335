import { Grid, Typography } from '@mui/material';
import React from 'react'
import keewe from './Images/keewe.png';
import './first.css';

const Keewecomp = () => {
  return (
    <Grid container className="imgg" sx={{pt:'12%', backgroundImage: `url(${keewe})`, height:"590px", backgroundSize:'cover',backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll'}}>
        <Grid item lg={1} xl={1.5} xs={1}/>
        <Grid item xl={10.5} lg={11} sx={{pl:'2%'}}>
       
           <Typography color="white" sx={{fontWeight:'500', fontFamily:'Roboto', fontSize:'40px'}}>K - Knowledge Production</Typography>
      
       
           <Typography color="white" sx={{fontWeight:'500', fontFamily:'Roboto', fontSize:'40px'}}>E - Employment Generation</Typography>
      
           <Typography color="white" sx={{fontWeight:'500', fontFamily:'Roboto', fontSize:'40px'}}>E - Entrepreneurship</Typography>
       
           <Typography color="white" sx={{fontWeight:'500', fontFamily:'Roboto', fontSize:'40px'}}>W- Wealth Creation</Typography>
      
           <Typography color="white" sx={{fontWeight:'500', fontFamily:'Roboto', fontSize:'40px'}}>E - Engine</Typography>
            <hr color="#3feba3" width="18%" align="left"/>
       </Grid>
    </Grid>
  )
}

export default Keewecomp
