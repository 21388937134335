import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import gg from './gg.jpg';
import ballu from './ballu.jpg'

const Comments = () => {
    const arr = [
        {
            comment: 'The course has been designed in a very self explanatory manner with emphasis on minute details.',
            img: gg,
            name: 'Gaurav',
            profile: 'Software Engineer',
        },
        {
            comment: 'I am very thankful to the developer of the course for providing a detailed information in a very easy language.',
            img: ballu,
            name: 'Balkar',
            profile: 'Online Student'
        }
    ]
    return (

        <Box sx={{ marginTop: '6%' }}>
            {arr.map((item) => {
                return (
                    <Box sx={{ border: '1px solid #ddd', padding: '17px 20px', marginBottom: '20px', borderRadius: '4px' }}>
                        <Typography sx={{ fontFamily: "Roboto,Helvetica Neue,Helvetica,Arial,sans-serif", fontSize: '1rem', lineHeight: '1.5', marginBottom: '15px', color: '#283034', fontWeight: '400' }}>
                            {item.comment}
                        </Typography>
                        <Box sx={{ display: 'flex' }}>
                            <Avatar
                                alt=""
                                src={item.img}
                                sx={{ width: 70, height: 70 }}
                            />
                            <Box sx={{ marginTop: '2%', marginLeft: '4%' }}>
                                <Typography sx={{ fontFamily: "Roboto,Helvetica Neue,Helvetica,Arial,sans-serif", fontSize: '1.075rem', lineHeight: '1', color: '#283034', fontWeight: '700', letterSpacing: '-.5px' }}>
                                    {item.name}
                                </Typography>
                                <Typography sx={{ fontFamily: "Roboto,Helvetica Neue,Helvetica,Arial,sans-serif", fontSize: '1rem', lineHeight: '1', marginBottom: '2px', color: '#858585', fontWeight: '400', marginTop: '7%' }}>
                                    {item.profile}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                );
            })}


        </Box>
    )
}

export default Comments