import React from 'react';
import { Grid, Box, Typography, Card, Button } from '@mui/material';
import gur from './Images/gur.png';
import gre from './Images/gre.png';
import sonu from  './Images/sonu.png';

const Expertise = () => {
  return (
    <Grid container sx={{ paddingTop: '100px' }}>
      <Grid container>

        <Grid item lg={12} xs={12} sm={12} md={12} display={"flex"} justifyContent={'center'}>
    
            <Typography sx={{ fontSize: '26px', fontWeight: '400',fontFamily:'Roboto',lineHeight:'1.5', paddingBottom:'2%'}}>Meet our Expertise</Typography>
       
        </Grid>

       </Grid>
     
      <Grid container >
        <Grid item lg={1}></Grid>
        <Grid container item xs={12} lg={10} md={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
          <Grid item xs={11} lg={3.5} md={4} sm={4} >
            <Card sx={{ borderRadius: '10px', margin: '4%' }}>
              <img src={gur} alt="" style={{width:'100%'}}/>

            </Card>
            <Card sx={{ boxShadow: '0px 5px 10px rgb(31 66 135 / 10%)', borderRadius: '10px', padding: '30px', marginTop: '-60px', marginLeft: '30px', zIndex: '99', position: 'relative' }}>
              <Typography sx={{ lineHeight: '1.2', fontSize: '1.2rem', color: '#1f2471', fontWeight: '700', fontFamily: 'Source Sans Pro' }}> Gurpreet Singh</Typography>
            </Card>
          </Grid>
          <Grid item xs={11} lg={3.5}  md={4} sm={4}>
            <Card sx={{ borderRadius: '10px', margin: '4%' }}>
              <img src={gre} alt="" style={{width:'100%'}}/>
            </Card>
            <Card sx={{ boxShadow: '0px 5px 10px rgb(31 66 135 / 10%)', borderRadius: '10px', padding: '30px', marginTop: '-60px', marginLeft: '30px', zIndex: '99', position: 'relative' }}>
              <Typography sx={{ lineHeight: '1.2', fontSize: '1.2rem', color: '#1f2471', fontWeight: '700', fontFamily: 'Source Sans Pro' }}> Amarjit Grewal</Typography>
            </Card>
          </Grid>
          <Grid item xs={11} lg={3.5}  md={4} sm={4}>
            <Card sx={{ borderRadius: '10px', margin: '4%' }}>
              <img src={sonu} alt="" style={{width:'100%'}}/>
            </Card>
            <Card sx={{ boxShadow: '0px 5px 10px rgb(31 66 135 / 10%)', borderRadius: '10px', padding: '30px', marginTop: '-60px', marginLeft: '30px', zIndex: '99', position: 'relative' }}>
              <Typography sx={{ lineHeight: '1.2', fontSize: '1.2rem', color: '#1f2471', fontWeight: '700', fontFamily: 'Source Sans Pro' }}> Dr. Sonu Grewal</Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
    </Grid>
  )
}

export default Expertise
