import { Card, Grid, Typography } from '@mui/material'
import React from 'react'
import './About.css';

const About2 = () => {
    return (
        <Grid container sx={{pt:'5%'}} lg={12} xl={12}>

            <Grid item sx={{ pb: '5%', textAlign: "center" }} lg={12} xl={12} sm={12} md={12}>
                
                <Typography sx={{ pb: '2%',fontSize:'26px', fontWeight:'400', fontFamily:'Roboto'}}>How Does <span style={{color:"#273773", fontWeight:'700'}}>CRESTBELL</span> Help You Achieve Your Goal?</Typography>
                <hr color="#3feba3" width="30%" />
            </Grid>

            <Grid item container sx={{ display: 'flex',justifyContent:'space-between' }}>
                <Grid item lg={1} xl={1} md={0.2} sm={0.2}/>
                <Grid item className="pdng" lg={3} xl={3} xs={12} md={3.5} sm={3.5} sx={{mb:'2%' }}>
                    <Card sx={{height:"90%",padding:'11px 18px 11px 18px', boxShadow:'0px 0px 0px 0.3px' , '&:hover': { backgroundColor: "#303574", color: 'white' , cursor:'pointer', height: '90%'} }}>
                        <Typography sx={{ fontWeight: '600', mb: '4%', fontSize:'24px', fontFamily:'Roboto', mt:'8%'}}>Value Addition</Typography>
                        <Typography sx={{ fontSize: '17px',fontFamily:'Roboto', pb: '4%'}}>
                            Catering to start-ups, small-scale, and micro-businesses, CRESTBELL offers an innovative set of functions through which organizations can boost their business operations with value-added flexible solutions.
                        </Typography>
                    </Card>
                </Grid>

                <Grid item className="pdng" lg={3} xl={3} md={3.5} sm={3.5} sx={{mb:'2%' }}>
                    <Card sx={{height:"90%",padding:'11px 18px 11px 18px', boxShadow:'0px 0px 0px 0.3px' , '&:hover': { backgroundColor: "#303574", color: 'white', cursor:'pointer', height: '90%'} }}>
                        <Typography sx={{fontWeight: '600', mb: '4%', fontSize:'24px', fontFamily:'Roboto', mt:'8%'}}>Extensive Network Effects</Typography>
                        <Typography sx={{ fontSize: '17px' ,fontFamily:'Roboto', pb: '4%'}}>
                            With CRESTBELL, associated organizations can experience significant
                            revenue streams by providing logistical support, learning programs, management studies, etc.
                        </Typography>
                    </Card>
                </Grid>

                <Grid item className="pdng" lg={3}  xl={3} md={3.5} sm={3.5} sx={{mb:'2%' }}>
                    <Card sx={{height:"90%",padding:'11px 18px 11px 18px', boxShadow:'0px 0px 0px 0.3px' , '&:hover': { backgroundColor: "#303574", color: 'white', cursor:'pointer', height: '90%'}  }}>
                        <Typography sx={{fontWeight: '600', mb: '4%', fontSize:'24px', fontFamily:'Roboto', mt:'8%'}}>Learn & Earn</Typography>
                        <Typography sx={{ fontSize: '17px' ,fontFamily:'Roboto', pb: '4%'}}>
                            Learn and excel in your preferred subjects through various online courses and enjoy learning at your own pace. Get the work relating to your skills from our partnered organizations and generate income.
                        </Typography>
                    </Card>
                </Grid>
                <Grid item lg={1} xl={1} md={0.2} sm={0.2}/>
            </Grid>
        </Grid>
    )
}

export default About2
