import { Paper, Typography } from '@mui/material'
import React from 'react'

function Sidepaper() {
  return (
    <Paper sx={{mt:'16%',p:'2%',mr:'4%',ml:'4%',boxShadow: '0 0 10px rgb(0 0 0 / 10%)'}}>
        <ul style={{lineHeight:'2'}}>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Certificate Lifelong</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Course Access Lifetime</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Globally Acceptable</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Anytime Anywhere</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Course with discussion form</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Placement Assistance</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Incubation Opportunities</Typography></li>
            <li><Typography sx={{fontFamily:'Roboto', fontSize:'19px', fontWeight:'500'}}>Resume Builder</Typography></li>
        </ul>
    </Paper>
  )
}

export default Sidepaper
