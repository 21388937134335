import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Typography, Paper, Box, Button } from '@mui/material';
import patiala from './Components/Images/patiala.jpg';
import ludhiana from './Components/Images/ludhiana.jpg';
import jalandhar from './Components/Images/jalandhar.jpg';
import bihar from './Components/Images/bihar.jpg';
import up from './Components/Images/up.jpg';
import bathinda from './Components/Images/bathinda.jpg';
import Rajasthan from "./Components/Images/Rajasthan.jpg"
import ModalJobs from "./Components/MoalJobs"
import { useState } from "react";
import Modal from '@mui/material/Modal';
// import "./Components/About.css"
import { useTheme } from '@mui/material/styles';
import './zonal.css'
import CloseIcon from '@mui/icons-material/Close';



export default function ZonalCarousel() {

  var items = [
    {
      image: ludhiana,
      zone: 'Ludhiana ', dataArr: [
        { Name: "Hardeep Singh", Location: "Ahemdgarh", Address: "Bhagga Computer Centre, Back side of Yash Painter Railway Road Ahmed Garh" },
        { Name: "Kamal Singh", Location: "Ahemdgarh", Address: "VPO, Amargarh, tehsil amargarh Near Bus Stand, GymWali Street District Malerkotla" },
        { Name: "Surinder Kaur", Location: "Malerkotla", Address: "Eagle Eye Institute, Near Jain Nursing Home , Bus Stand Road Maleroktla" }, { Name: "Sarbjit Singh", Location: "Dhuri", Address: "Eagle Eye Institute, MK Bye Pass Road Dhuri 148024 " },
        { Name: "Gurvinder Singh", Location: "Model Town", Address: "Guru Nanak Institute of Management and technology Gujarkhan Campus, Model Town Ludhiana" },
        { Name: "Anil Aggarwal", Location: "Raikot", Address: "Aggarwal Institute of IT and Management Opposite Bus Stand Talwandi Road Raikot 141109 Ludhiana, Punjab " },
        { Name: "Raghav Aren & Sons", Location: "Sunam", Address: "PTU Support centre, Near SUS College Sunam" },
        { Name: "Punam Sharma", Location: "Sunam", Address: "Maa Saraswati College of Girls Education, Arora Enclave, Mata Modi Chowk Sunam 148028" },
        { Name: "Amarjit Singh", Location: "Ludhiana", Address: "Main Road Kotmangal Singh, Shimlapuri Ludhiana" },
        { Name: "Satwant Singh ", Location: "Kup Kalan", Address: "Guru Kirpa Study Center Village Kup Kalan District Malerkotla" },
        { Name: "Rana Gulbir Singh", Location: "Tarn Taran", Address: "Tulip College of English Bath Road, Opposite New Grain Market, Tarn Taran" },
        { Name: "Bhim Sain", Location: "Jagraon", Address: " J S Global Institute, Near Ajanta Petrol Pump, Jagraon Distt : Ludhiana" },
        { Name: "Mr. Dalveer ", Location: " Sangrur", Address: "Quick E-Adhaar service, Shop No. 5 , Nabha Gate, Sangrur 143001" },
        { Name: "Mr. Rehman Rakhtar ", Location: "Malerkotla", Address: "Sangrur Institute of Education,Defence Colony, Grewal chowk" },
        { Name: "Mr. Satnam Singh", Location: "Bhawanigarh", Address: " Near Bus StandNear Mobile Tower, Bhawanigarh District Sangrur" },
        { Name: "Navjot Singh ", Location: "Samrala", Address: "Rama Mandi, Khanna Road, Samrala-141114 " },
        { Name: "Amrit Pal", Location: "Machhiwara", Address: "Above Punjab and Sind Bank, Samrala Road, Machhiwara" }
      ]
    },
    {
      image: jalandhar,
      zone: 'Amritsar', dataArr: [{ Name: "Amir Singh", Location: "Adampur", Address: "Netcom Computers PTU Study centre Main road Adampur DOBA District Jalandhar Punjab" }, { Name: "Dr. Gagandeep Khindria", Location: "Batala", Address: "Satsar Institute, I/S Datsar Hospital Mann Nagar Dera Baba Nanaj Road Batala , Punjab 143505" }, { Name: "Savinder Singh", Location: "Pathankot", Address: "MRSPTU Online University Certified Courses(CIIMS) Admission Facilitator & Logistics Support Centre JALANDHAR DALHOUSIE BYE PASS, PATHANKOT-145001" }, { Name: "Jaswant Kaur", Location: "Dasuya", Address: "Digital Tech Online Education centre, Ghogra, Tehsil Dasuya, Hoshiarpur" }, { Name: "Manjit Singh", Location: "Hoshiarpur", Address: "SMC Overseas Educare SCO 46 FF, Above Global Pharmaceuticals, Kamalpur, Near Civil Hospital, Jalandhar road Hoshiarpur(Punjab)" }, { Name: "Baljinder Kaur", Location: "Kapurthala", Address: "Netcom Skills Institute Adjoining The Visa  Doctors, Ramneek Chownk Kapurthala" }]
    },
    {
      image: patiala,
      zone: 'Patiala ', dataArr: [
        { Name: "Jasvir Singh", Location: "Nabha Road", Address: "WebTech Computer Education center Opposite Milan palace, Nabha, Patiala" },
        { Name: "Gurjant Singh", Location: "Khamano", Address: "Near Old SDM Office Above Seabird Grain Market Khamano, Fatehgarh Sahib" },
        { Name: "Raghav Aren & Sons", Location: "Zirakpur", Address: "Zirakpur" },
        { Name: "Subhash Dawar", Location: "Patiala", Address: "Guru Nanak Institute of Medical Technology Old Factory Area,Patiala" },
        { Name: "Sarbjit Singh", Location: "Near Punjabi University", Address: "Eagle eye Institute, Opposite Punjab and Sind Bank, Near Punjabi University, Patiala" },
        { Name: "Kulwant singh", Location: " Tehsil Road near BSNL Exchange Samana 147101", Address: "Om Sai Ram Education Center Tehsil Road near BSNL Exchange Samana 147101" },
        { Name: "Manmeet singh Bhatti", Location: " Near Bus Stop Kharar 140301", Address: "Zillion Telesoft India Pvt Ltd SCO 6 Near Bus Stop Kharar 140301" },
        { Name: "Gurmit Singh Chawla", Location: "Sirhnd", Address: "Jazz Logistics Support centre#436, Lane No. 8 Ghuman Nagar,Sirhind Road, Patiala" },
        { Name: "Vishi ", Location: "Rupnagar ", Address: " TK Sysytems SCO 7 Adda market, district Ropar Patiala" },
        { Name: "Atul Verma", Location: "Patiala ", Address: "Lakshya Netcom Opposite British Co-ed School Lower Mall , Patiala" },
        { Name: "Kamal Kumar", Location: "Ropar", Address: "KCM Institute Of Education, Garden Colony"},
        { Name: "Mr. Taranjeet Singh", Location: " Bhawanigarh", Address: "CSA Education Centre, Super Market, Near Post Office, Baba Ajit Nagar, Bhawanigarh, Punjab 148026" }


      ]
    },
    {
      image: bathinda,
      zone: 'Bathinda', dataArr: [{ Name: "Sunil Kumar", Location: "Abohar", Address: "Net Gyan Educational & Welfare Society, Near Bus Stand, Abohar ,distt Fazilka" },
      { Name: "Sunil Kumar", Location: "Fazilka", Address: "Net Gyan Educational & Welfare Society,Adarsh Nagar Phirni Road   Fazilka" },
      { Name: "Jatinder Pal Singh", Location: "Jalalabad", Address: "Brilliant Institute, DAV College Road, Near Shivalik Sen. Sec School, Jalabad-152001" }, { Name: "Bhai Bhelo Computers", Location: "Bhagta Bhaika", Address: "Bhai Bhelo Computers Bahi Bhelo Road, Bhagta Bhai K+L60a 151206" },
      { Name: "Sarbjeet Singh", Location: "Talwandi Bhai", Address: "Govt. Girls School Street Talwandi Bhai" }, { Name: "Govt. Girls School Street Talwandi Bhai", Location: "Muktsar", Address: "Bathinda Road, Shri Muktsar sahib" }, { Name: "Mukhtiar Singh", Location: "Zira", Address: "The North View Mallo Ke road near Vishkarma Gurudwara, Zira District Ferozepur 142047" }, { Name: "Sarbjeet Singh", Location: "Faridkot", Address: "Near Bus stand Faridkot" }, { Name: "Jatinder Pal Singh", Location: "Ferozpur Cantt", Address: "Khalsa Gurudwara Complex Above SBI Bank Ferozpur Cantt" }, { Name: "Kuldeep Singh", Location: "Ferozpur Cant.", Address: "Career Support Centre Bye Pass Satiye Wala Chouw Zira Road Ferozpur " }, { Name: "Som Singh", Location: "Mudki", Address: "Gill road mudki , Behal educational and online services" }, { Name: "Rashpal", Location: "Sadiq", Address: "High Tech Educationl Institute ,Opposite State Bank of India, Faridkot Road, Sadiq" }, { Name: "Balwinder Singh", Location: " Pindi", Address: " FF Road VPO Pindi, Teh. Guru Har Sahai, Distt. Ferozepur" }, { Name: "Mr. Ram Singh", Location: "Ram Pura Phul", Address: "Bajakhana Road Opposite Aklia Road, Near Bus Stand, Jalal, Tehsil Rampura Phul, Bathinda" },
      ]
    },
    {
      image: Rajasthan,
      zone: 'Rajasthan', dataArr: [{ Name: "Seema Verma", Location: "Alwar", Address: "053,Alpine Educare Institute, Plot no. 5,6 Saroj Nagar, Alwar, Rajasthan" },
     
      ]
    },
    
    // {
    //   image: up,
    //   zone: 'UP Zone', phone: '7889105509', email: 'admin@jnajung.com', info: 'The state of UP covers all the cities/districts within the state.'
    // },

    // {
    //   image: bihar,
    //   zone: 'Bihar Zone', phone: '7889105509', email: ' admin@jnajung.com', info: 'The state of Bihar covers all the cities/districts within the state.'
    // },

   
  ]

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  console.log(data)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  // const { handleClosee } = this.props;
  return (
    <Grid container sx={{ marginTop: "80px" }}>
      <Grid item lg={12} xl={12} xs={12} sm={12} md={12} margin='2% 7% 0% 7%' sx={{ textAlign: { xs: 'center' } }}>
        <Typography variant='h5' fontWeight='bold'>List of Admission and Logistics Support Centre</Typography>
      </Grid>


      <Grid container item xl={12} lg={12} xs={12} sm={12} md={12} margin='2%' >
        {/* <Carousel responsive={responsive}
          infinite={true}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed='2000'

        > */}
        <Grid lg={0.6} xl={0.6} />
        {
          items.map((item, i) => {
            return (
              <Grid xl={2.2} lg={2.2} xs={12} sm={5.8} md={5.8} sx={{ height: '220px', borderRadius: '10px', position: "relative", margin: '2px', cursor: 'pointer' }}>
                <img className='img' src={item.image} style={{ height: '200px', width: '100%', borderRadius: '10px' }} onClick={() => { handleOpen(); setData(item) }} />
                <p className='zone' style={{ position: "absolute", top: "30%",width: "100%",  textAlign: "center", color: "white", fontSize: "1.45rem", fontWeight: 700 }} onClick={() => { handleOpen(); setData(item) }}>{item.zone}</p>
              </Grid>
            )
          }
          )
        }
        {/* </Carousel> */}
      </Grid>

      {/* <Grid item lg={12} xs={12} sm={12} md={12} className="Grid" display='flex' margin='2% 7%' sx={{justifyContent:'center'}}>
        <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px', marginRight:'1%' , position:"relative"}}>
          <img src={patiala}  style={{height:'220px', width:'250px' , borderRadius:'10px' }} onClick={()=>{handleOpen(); setData({zone: 'Patiala Zone', })}}/>
          <p style={{position:"absolute",top:"40%" , left:"30%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Zone A</p>
        </Paper>
        <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%' , position:"relative"}}>                                                                                         
          <img src={bathinda}  style={{height:'220px', width:'250px', borderRadius:'10px'}} onClick={()=>{handleOpen(); setData({zone: 'Bathinda Zone', address:"Artcave Printers, 1978/2, Maharaj Nagar, Circuit House Road, Ludhiana",phone: '9876668999', email: 'abpbathinda@crestbellsupport.com', website: 'http://artcaveservices.com' , info:"The Bathinda Zone includes the following districts: Bathinda, Mansa, Muktsar, Ferozepur, Fazilka, Abohar"})}}/>
          <p style={{position:"absolute",top:"40%" , left:"20%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Zone B</p>
        </Paper> */}
      {/* <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%', position:"relative"}}>
          <img src={up}  style={{height:'220px', width:'250px', borderRadius:'10px'}} onClick={()=>{handleOpen(); setData({zone: 'UP Zone', address:"", phone: '8437100010', email: 'abpup@crestbellsupport.com' , info:"The state of UP covers all the cities/districts within the state." })}}/>
          <p style={{position:"absolute",top:"40%" , left:"30%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>UP Zone</p>
        </Paper> */}
      {/* <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%', position:"relative"}} onClick={()=>{handleOpen(); setData({zone: 'Ludhiana Zone', address:"Baba Deep Singh Associates, 106 New Model Gram, Opposite Verka Milk Booth, Ludhiana"  , phone: '9872356000', email: 'abpludhiana@crestbellsupport.com'  , info:"The Ludhiana Zone includes the following districts: Ludhiana, Moga, Faridkot, Barnala"})}}>
          <img src={ludhiana}  style={{height:'220px', width:'250px', borderRadius:'10px'}}/>
          <p style={{position:"absolute",top:"40%" , left:"20%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Zone C</p>
        </Paper> */}
      {/* <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%', position:"relative"}}>
          <img src={bihar}  style={{height:'220px', width:'250px', borderRadius:'10px'}} onClick={()=>{handleOpen(); setData({zone: 'Bihar Zone', address:"", phone: '9779725565', email: 'abpbihar@crestbellsupport.com', info:"The state of Bihar covers all the cities/districts within the state." })}}/>
          <p style={{position:"absolute",top:"40%" , left:"30%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Bihar Zone</p>
        </Paper> */}
      {/* <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%', position:"relative"}}>
          <img src={jalandhar}  style={{height:'220px', width:'250px', borderRadius:'10px'}} onClick={()=>{handleOpen(); setData({zone: 'Jalandhar Zone',  address:"BH EDU TECH, SCS 78 Chotti Baradri Part 2 Opposite pims hospital Jalandhar",phone: '9876316000', email: 'abpjalandhar@crestbellsupport.com' , info:"The Amritsar & Jalandhar Zone includes the following districts: Amritsar, Taran Taran, Gurdaspur, Pathankot, Jalandhar, Nawashahr, Kapurthala, Hoshiarpur"})}}/>
          <p style={{position:"absolute",top:"40%" , left:"20%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Zone D</p>
        </Paper> */}
      {/* <Paper elevation={3} className='zonal-img' sx={{height:'220px', width:'250px', borderRadius:'10px',marginRight:'1%', position:"relative"}}>
          <img src={Rajasthan}  style={{height:'220px', width:'250px', borderRadius:'10px'}} onClick={()=>{handleOpen(); setData({zone: 'Rajasthan Zone', phone: '9414174974', email: 'abprajasthan@crestbellsupport.com' ,info:"The state of Rajasthan covers all the cities/districts within the state.  " })}}/>
          <p style={{position:"absolute",top:"40%" , left:"20%" , color:"white" , fontSize:"1.45rem" , fontWeight:700}}>Rajasthan Zone</p>
        </Paper> */}
      {/* </Grid> */}
      {/* <Grid item lg={12} xs={12} sm={12} md={12} className="Grid" display='flex' margin='2% 7%'>
 
           </Grid> */}
      {/* </ImageList> */}

      {/* <Box sx={{ maxWidth: 400, flexGrow: 1,
                display:{xs:'block',lg:'none'} 
                }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'gray',
        //   marginLeft:"4%",
        }}
      >
        <Typography sx={{color:'white',fontWeight:'bold',fontSize:'25px',textAlign:'center'}}>{items[activeStep].zone}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {items.map((step, index) => (
          <div key={step.zone}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 255,
                  display: 'block',
                  maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.image}
                alt={step.zone}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ overflowY: "scroll" }}
      >
        {/* <CloseIcon onClick={handleClosee} /> */}
        <ModalJobs handleOpen={handleOpen} data={data} open={open} handleClose={handleClose} />
      </Modal>
    </Grid>
  )
}