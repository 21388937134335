import { Grid, Typography } from '@mui/material'
import React from 'react'
import contactus from './contactus.png';

const  firstcomp = () => {
  return (
    <Grid container>
      <Grid container item lg={12} xl={12} sx={{ mt:'-7%', backgroundImage: `url(${contactus})`, height: '650px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
        <Grid item sx={{backgroundColor: '#273773', opacity: 0.5, width: '100%', height: '100%', position: 'relative'}}>
        </Grid>
        <Grid item sx={{position: 'absolute', pt:'28%', pl: '13%'}} >        
        <Typography sx={{color: 'white', fontSize: '4rem', fontWeight: 600, fontFamily: "Roboto"}}>Contact Us</Typography>
        <hr color="#3feba3" width="60%"/>
        </Grid>

                </Grid>
                
    </Grid>
  )
} 

export default firstcomp
