import React, { useState } from 'react'
import { Grid, Typography, TextField, Button } from "@mui/material"
import { Box } from '@mui/system'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import contactus from './contactus.png';
import './contact.css'
import axios from 'axios';

const Contact = () => {

    const [data, setData] = useState({})

    const Submit = async()=>{
      try{  await axios({
            url:"https://cms.keewesolutions.com/mailer/contact",
            method:"POST",
            data:data

        }).then(res=>{
            console.log(res)
            setData({name:"",phone:"",email:"",message:""})
            alert("Submitted successfully")
        })
    }
    catch(err){
        console.log(err)
        setData({name:"",phone:"",email:"",message:""})
        alert("Error")
    }
    
    }

    return (
        <Grid container sx={{mt: '2%'}}>
            {/* <Grid item sx={{ backgroundImage: `url(${contactus})`, height: '400px' }} >
                </Grid> */}
            <Grid lg={1.5} md={0.2} sm={0.2} xs={0.5}/>
            <Grid item container lg={4.5} xl={4.5} md={5.8} sm={5.8} xs={11}  sx={{ boxShadow: '0px 0px 10px 0px #63f4bb', padding: "25px", mb:'2%' ,borderRadius:'14px'}}>

                <Typography sx={{ fontSize: '28px', fontWeight: 700, margin: 'auto' }}>Arrange a meet or a call</Typography>
                <Grid item >
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    Submit()}}>
                    <TextField value={data.name} required sx={{ mb: '16px', mt: '30px' }} inputProps={{style:{height:'12px'}}} onChange={(e)=>{
                        setData({...data,name:e.target.value})
                    }} id="outlined-basic" placeholder="Name*" variant="outlined" fullWidth />


                    <TextField value={data.phone} required sx={{ mb: '16px' }} inputProps={{style:{height:'12px'}}} type="number" onChange={(e)=>{
                        setData({...data,phone:e.target.value})
                    }} id="outlined-basic" placeholder="Phone no.*" variant="outlined" fullWidth />


                    <TextField value={data.email} required sx={{ mb: '16px' }} inputProps={{style:{height:'12px'}}} type='email' onChange={(e)=>{
                        setData({...data,email:e.target.value})
                    }} id="outlined-basic" placeholder="Email*" variant="outlined" fullWidth />

                     
                          <textarea className='fld' value={data.message} style={{ mb: '16px',borderRadius:'4px',padding:'16.5px 14px' ,width: '94.2%',border:'1px solid #80808063',fontFamily:'Roboto',fontWeight:'400',fontSize:'1rem'}} id="outlined-basic" onChange={(e)=>{
                        setData({...data,message:e.target.value})
                    }} placeholder="Message" variant="outlined" multiline rows={4} />
                    
                  

                    <Button type="submit" variant='contained'  sx={{ backgroundColor: '#273773', padding: '10px', fontSize: '17px', fontWeight: 500,marginTop:'3%',textTransform:'capitalize' ,'&:hover':{bgcolor:'#273773'}}} fullWidth>Submit</Button>
                    </form>
                </Grid>


            </Grid>
            {/* <Grid xs={0.5} /> */}
            <Grid container item className='contact' lg={6} xl={6}  md={6} sm={6} xs={12} sx={{ display: 'block', pl: '20px', pt: '6%' }}>
                <Grid item sx={{ display: 'flex' }} xs={12} className='ak'>
                    <LocationOnIcon sx={{ width: '2em', height: '2em', color: "#273773", pt: '10px' }} />
                    <Grid item sx={{ ml: '1%' }} xs={12}>
                        <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>Visit Us</Typography>
                        <Typography>#SCO 218-219, Sector-34 A 2nd Floor, Chandigarh</Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: 'flex', mt: '6%' }} className='ak'>
                    <WifiCalling3Icon sx={{ width: '2em', height: '2em', color: "#273773", pt: '10px' }} />
                    <Grid item sx={{ ml: '1%' }}>
                        <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>Call Us</Typography>
                        <Typography>+91-9875944053</Typography>
                    </Grid>
                </Grid>
                <Grid item sx={{ display: 'flex', mt: '6%', mb: '3%' }} className='ak'>
                    <AttachEmailIcon sx={{ width: '2em', height: '2em', color: "#273773", pt: '10px' }} />
                    <Grid item sx={{ ml: '1%' }}>
                        <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>Email Us</Typography>
                        <Typography>info@crestbellsupport.com</Typography>
                        <Typography >supportcentre@crestbellsupport.com</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Contact
