import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Keewe from './Images/keewe11.jpg';
import './map.css';

export default function Keewecomp1() {
    return (

        <Grid container sx={{ }}>
            <Grid item lg={1} xl={1.5} sm={0.2} md={0.2} />
            <Grid item xl={4.5} lg={5} xs={12} sm={6} md={6} sx={{ paddingTop: "6%", textAlign: 'left', pl: '2%', pr: '2%' }}>
                <Typography sx={{ fontSize: "25px", fontWeight: 600, fontFamily: "EB Garamond, Sans-serif" }}>“KEEWE Engine for L-Earning Solutions”</Typography>
                <hr color="#3feba3" width="25%" align="left" />
                <Typography sx={{ fontSize: "17px", fontFamily: "Roboto Slab, Sans-serif", textAlign: "justify", paddingTop: "20px", wordSpacing: '1' }}>Avail L-Earning opportunities with KEEWE Engine. Furthermore, KEEWE Engine defined as Knowledge production, Employment generation, Entrepreneurship, Wealth creation, and Enterprises engine allows you to share your knowledge and boost your wealth. Also, in order to create value for both partners and customers, this ultimately digitized Ed-tech platform – CRESTBELL allows students to experience flexible learning and also helps collaborators to create courses, advertise and sell courses and build their brand. </Typography>
            </Grid>
            <Grid item xl={4.5} lg={5} xs={12} sm={4} md={4} sx={{  }}>
                <img className="imaage" src={Keewe} style={{ width: "550px" ,marginTop:'8%'}} />
            </Grid>
            <Grid item xl={1.5} lg={1} />
        </Grid>

    );
}