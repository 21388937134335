import React from 'react';
import { Box, Typography, Card } from '@mui/material';

const About = ({ courseArray, teacher }) => {
    return (
        <Box>
            <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem', marginTop: '1rem' }}>
                Requirements
            </Typography>

            <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.125rem', fontWeight: '400', color: '#283034', marginBottom: '0.5rem' }}>
             Basic knowledge of Computer



                
            </Typography>

            <Card sx={{ backgroundColor: '#f6f6f6', border: '1px solid #f0f0f0', margin: "25px 0px 0px 0px", padding: '30px 25px', borderRadius: '10px' }}>
                <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.35rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem' }}>
                    ABOUT THE AUTHOR
                </Typography>
                {/* {teacher != undefined ? */}
                    <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1rem', fontWeight: '300', color: '#283034', marginBottom: '1rem' }}>
                    Dr. Munish Jindal (M.E., Ph.D., UGC-NET, GATE) earned his Master's degree in Computer Science & Engineering in 2008 and his Ph.D in 2015 from Thapar Institute of Engineering & Technology, Patiala. He is currently working as an Assistant Professor in the Department of Computational Sciences at Maharaja Ranjit Singh Punjab Technical University, Bathinda. His research interests include Character Recognition, Handwriting Recognition, Computer Vision, Machine Learning, and Pattern Recognition. He has authored 2 books and guided 5 Ph.D. scholars. Besides, he has over 150 research articles published in prestigious international journals and conference proceedings. Google scholar lists over 2250 citations for his research articles.
                    </Typography>
                    {/* : null
                } */}
            </Card>
        </Box>

    )
}

export default About