import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, Typography, Paper, Box, Button } from '@mui/material';
import patiala from './Components/Images/patiala.jpg';
import ludhiana from './Components/Images/ludhiana.jpg';
import jalandhar from './Components/Images/jalandhar.jpg';
import bihar from './Components/Images/bihar.jpg';
import up from './Components/Images/up.jpg';
import bathinda from './Components/Images/bathinda.jpg';
import Rajasthan from "./Components/Images/Rajasthan.jpg"
import ModalJobs from "./Components/MoalJobs"
import { useState } from "react";
import Modal from '@mui/material/Modal';
// import "./Components/About.css"
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  
 

};
export default function ZonalCarousel() {

  
   
    
    // {
    //   image: up,
    //   zone: 'UP Zone', phone: '7889105509', email: 'admin@jnajung.com', info: 'The state of UP covers all the cities/districts within the state.'
    // },

    // {
    //   image: bihar,
    //   zone: 'Bihar Zone', phone: '7889105509', email: ' admin@jnajung.com', info: 'The state of Bihar covers all the cities/districts within the state.'
    // },

   
  

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
 

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };



  // const { handleClosee } = this.props;
  return (
    <Grid container sx={{ }}>
      <Grid item lg={12} xl={12} xs={12} sm={12} md={12} margin='2% 7% 0% 7%' sx={{ textAlign: { xs: 'center' } }}>
        <Typography variant='h5' fontWeight='bold'>State Associates Business Partners</Typography>
      </Grid>


      <Grid container item xl={12} lg={12} xs={12} sm={12} md={12} margin='2%' >
        {/* <Carousel responsive={responsive}
          infinite={true}
          showDots={true}
          autoPlay={true}
          autoPlaySpeed='2000'

        > */}
        <Grid lg={0.6} xl={0.6} />
       
              <Grid xl={2.2} lg={2.2} xs={12} sm={5.8} md={5.8} sx={{ height: '220px', borderRadius: '10px', position: "relative",  margin: '2px', cursor: 'pointer' }}>
                <img className='img' src={patiala} onClick={handleOpen} style={{ height: '200px', width: '100%', borderRadius: '10px' }} />
                <p className='zone' onClick={handleOpen} style={{ position: "absolute", top: "30%", width: "100%", textAlign: "center", color: "white", fontSize: "1.45rem", fontWeight: 700 }} >Uttrakhand</p>
              </Grid>
              <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ }}
      >
        {/* <CloseIcon onClick={handleClosee} /> */}
        <Box sx={style} >
        <Grid container sx={{justifyContent:'end'}}>
                    
                    <button onClick={handleClose} style={{height:'28px'}}>x</button>
                </Grid>
           <h4>Uttrakhand</h4>
           <h5>Name: Mr. Komalpreet Singh</h5>
           <h5>Phone no. : 8146041413</h5>
        </Box>
      </Modal>
        {/* </Carousel> */}
    
      <Grid xl={2.2} lg={2.2} xs={12} sm={5.8} md={5.8}  sx={{ height: '220px', borderRadius: '10px', position: "relative",  margin: '2px', cursor: 'pointer' }}>
                <img className='img' src={ludhiana} onClick={handleOpen1} style={{ height: '200px', width: '100%', borderRadius: '10px' }} />
                <p className='zone' onClick={handleOpen1} style={{ position: "absolute", top: "30%", width: "100%", textAlign: "center", color: "white", fontSize: "1.45rem", fontWeight: 700 }} >Haryana</p>
              </Grid>
              <Modal
        aria-labelledby="transition-modal-title"        
        aria-describedby="transition-modal-description"
        open={open1}
        onClose={handleClose1}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ }}
      >
        {/* <CloseIcon onClick={handleClosee} /> */}
        <Box sx={style} >
        <Grid container sx={{justifyContent:'end'}}>
                    
                    <button onClick={handleClose1} style={{height:'28px'}}>x</button>
                </Grid>
           <h4>Haryana</h4>
           <h5>Name: Mr. Swaran Sandhu</h5>
           <h5>Phone no. : 8054971000</h5>
        </Box>
      </Modal>
        {/* </Carousel> */}
      
      <Grid xl={2.2} lg={2.2} xs={12} sm={5.8} md={5.8} sx={{ height: '220px', borderRadius: '10px', position: "relative",  margin: '2px', cursor: 'pointer' }}>
                <img className='img' src={bathinda} onClick={handleOpen2} style={{ height: '200px', width: '100%', borderRadius: '10px' }} />
                <p className='zone' onClick={handleOpen2} style={{ position: "absolute", top: "30%", width: "100%", textAlign: "center", color: "white", fontSize: "1.45rem", fontWeight: 700 }} >Himachal Pradesh</p>
              </Grid>
              <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open2}
        onClose={handleClose2}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ }}
      >
        {/* <CloseIcon onClick={handleClosee} /> */}
        <Box sx={style} >
        <Grid container sx={{justifyContent:'end'}}>
                    
                    <button onClick={handleClose2} style={{height:'28px'}}>x</button>
                </Grid>
        <h4>Haryana</h4>
           <h5>Name: Mr. Swaran Sandhu</h5>
           <h5>Phone no. : 8054971000</h5>
        </Box>
      </Modal>
        {/* </Carousel> */}
      </Grid>
      
</Grid>
      

    
   
  )
}