import React, { useState, useEffect } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import All from "./All";
import { useLocation, useRoutes } from "react-router-dom";
import loadgif from "./Loading.gif"



export default function CourseDetails1({id}) {
    const location = useLocation()

    let querry ;
    if(id){
        querry = {courseId:id}
    }else{
        querry = location.state
    }
   

    const [currentCourseId, setCurrentCourseId] = useState('')
    const [teacher, setTeacher] = useState('')
    const [courseArray, setCourseArray] = useState(null)
   

    // if (querry.courseId != null && querry.courseId != undefined) {
       
    //     if (querry.courseId != currentCourseId) {
    //         setCurrentCourseId(querry.courseId)
    //     }
    // }

    const [loading, setLoading] = useState(false)


    useEffect(() => {

        const get_teacher = async () => {
            try {
                const teacherData = await axios({
                    url: "http://localhost:8080/db/getTeacherData",
                    method: "GET",
                    params: {
                        "courseId": currentCourseId
                    }


                })
                console.log(teacherData.data.response.Items[0])
                setTeacher(teacherData.data.response.Items[0])

            } catch (e) {
                console.log(e)
            }
        }
        const get_course_details = async () => {
            try {
                const courseDetails = await axios({
                    url: "http://localhost:8080/db/fetchcoursedatawp",
                    data: {
                        'courseIds': [
                            querry.courseId
                        ]
                    },
                    headers: {
                        "Access-Control-Allow-Origin": '*'
                    },
                    method: "POST"
                })
                
                if (courseDetails.data != "error") {
                   console.log(courseDetails.data.data[0])
                    setCourseArray(courseDetails.data.data[0])
                    setLoading(false)
                }
            } catch (e) {
                console.log(e)
            }
        }




        if (currentCourseId.length != 0) {

            get_course_details()
            get_teacher(currentCourseId)

        }


    }, [currentCourseId])
    return (<>
        {loading ? <Grid container lg={12} xs={12} sx={{ width: "fit-content", margin: "auto" }}><img src={loadgif} style={{ margin: "auto", width: "-webkit-fill-available" }} /></Grid> : <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
{/* <p>dfhymbcsghkuhsxbmsjcfhgvxm vkcb</p> */}
                {/* {courseArray != null ? ( */}
                <All courseArray={courseArray} teacher={teacher} id={id} />
                {/* ) : null} */}


            </Grid>
        </Grid>}
    </>

    );
};


