import React, { useEffect, useState } from 'react';
import { Button, Grid, Box, Typography, TextField } from '@mui/material'
import { NavLink } from "react-router-dom"
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import './map.css'
import logoshabd from '../Components/Images/logoshabd.png';


const CourseCards = () => {
    const [courseArray, setCourseArray] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [view, setView] = useState(false)
    const [value, setValue] = useState("")
    const get_courses = async () => {
        try {
            const results = await axios({
                url: "https://apsc.mrsptuonline.com/data/getcourse1",
                params: {
                    "category": "Personality Development",
                },
                method: "GET"
            })
            console.log(results.data.data.Items)
            setCourseArray(results.data.data.Items)
        } catch (e) {
            console.log(e)
        }
    }

    const getAllCourses = async () => {
        await axios({
            url: "https://apsc.mrsptuonline.com/data/allCourses",
            method: "POST"
        }).then(res => {
            console.log(res.data)
            setAllCourses(res.data.Items)
        })
    }

    useEffect(() => {
        get_courses();
        getAllCourses();
    }, [])

    return (
        <Grid container xs={12} lg={12} xl={12} sm={12} md={12} sx={{ backgroundColor: '#f8f9fb',marginTop:'4%' }}>
            <Grid lg={1} xl={1} sm={0.5} md={0.5} />
            <Grid item container xs={12} lg={10} xl={10} sm={11} md={11} marginTop='50px' marginBottom={'50px'}>
                <Grid item xs={12} lg={3} xl={4.5} sm={6} md={6} justifyContent='space-between' marginTop={'20px'}>
                  
                </Grid>
                <Grid item lg={4} xl={3.5} sm={3.5} md={3.5} xs={8}>

                    <Paper
                        component="form"

                        sx={{ padding: '2px 4px', display: 'flex', alignItems: 'center', mt: '20px', ml: "10px" }}
                    >

                        <InputBase
                            sx={{ mt: 1, flex: 1 }}
                            placeholder="    Search"
                            inputProps={{ 'aria-label': 'search google maps' }}
                            onChange={(e)=>{
                                setValue(e.target.value)
                            }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>

                    </Paper>

                </Grid>
                <Grid item xs={3.5} lg={2.8} xl={3.8} sm={2} md={2} sx={{
                    textAlign: "right", marginTop: '20px',}}>
                    </Grid>
                { allCourses.map((item) => {
                    // if (item.price != undefined) {
                        return (
                            <Grid item xs={12} lg={3} xl={3} sm={6} md={6} marginTop='40px' padding={"12px"} >
                                <NavLink
                                    to={'/Crestbellpage'} state={{ courseId: item.courseId }}
                                    style={{ textDecoration: 'none' }}>
                                  <Grid>
                                    <Box sx={{ height: '520px', width: '100%', border: '1px solid #ddd', boxShadow: '0 50px 100px -20px rgb(50 50 93 / 25%)', textAlign: 'left', borderRadius: '10px', '&:hover': { boxShadow: '0 0 0 0' } }}>
                                        <img src={"https://storage-mediaoutput.s3.ap-south-1.amazonaws.com//thumbnails/" + item.courseId + ".png"} style={{ width: '100%', height: '230px', borderRadius: '5px' }} />
                                        <Box>
                                            <Grid marginTop={'8px'} marginLeft='5px' display='flex'>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Languages:</Box>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>English</Box>
                                                <Box sx={{ margin: '5px', width: 'auto', fontSize: '.915rem', borderRadius: '4px', backgroundColor: '#f0f1f2', height: '25px', textAlign: 'center', color: '#333' }}>Hindi</Box>
                                            </Grid>

                                            <Grid marginTop={'8px'} marginLeft='10px'>
                                                <Typography sx={{ fontSize: '1.15rem', color: '#283034', fontWeight: '700', fontFamily: 'Muli,sans-serif' }}>{item.title}</Typography>
                                                <Typography sx={{ fontSize: '1.05rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>4.5 (26 Ratings)</Typography>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>   </Typography>



                                                <div style={{ display: 'flex' }}>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif' }}>Fee:</Typography>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif', textDecoration: 'line-through', marginLeft: '10px' }}>₹ 5000 </Typography>
                                                    <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#333', fontWeight: '800', fontFamily: 'Muli,sans-serif', marginLeft: '10px' }}></Typography>

                                                </div>
                                                <Typography sx={{ fontSize: '1.15rem', marginTop: '10px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}></Typography>
                                                <div style={{display:'flex',justifyContent:'center'}}>
                                                     <Button variant='contained' sx={{ margin: 'auto', backgroundColor: '#ff1f59',  '&:hover': { backgroundColor: 'grey',  color: '#ff1f59' }, fontSize: '14px', padding: '20px', height: '55px', color: 'white', marginTop: '5px', marginBottom: '4px' }} ><a  style={{color:'white',textDecoration:'none','&:hover':{color:'#ff1f59',backgroundColor:'white'}}} >Enroll for Free</a></Button>
                                                </div>
                                               
                                            </Grid>
                                            <Grid marginTop={'8px'} marginLeft='10px' display={'flex'}>
                                         
                                                <Typography sx={{ fontSize: '1rem', marginTop: '8px', color: '#888', fontWeight: '400', fontFamily: 'Muli,sans-serif' }}>Powered by : </Typography>
                                               <img src={logoshabd} alt="" style={{width:'50%'}}/> 
                                            </Grid>
                                        </Box>
                                       
                                    </Box>
                                    </Grid>
                                </NavLink>
                            </Grid>
                        )
                    // }
                })}
            </Grid>

        </Grid>


    )
}

export default CourseCards;