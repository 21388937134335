import { Grid, Typography } from '@mui/material'
import React from 'react'
import Zone from '../Zone'
import cbell from './cbell.png';

const Footer2 = () => {
  return (
    <Grid container bgcolor="#273773" color="white" padding={'2%'}>
      <Grid lg={0.5} />
      <Grid item lg={2} xs={12} sx={{}}>
        {/* <img className='lo' src={cbell} alt="" style={{ marginTop: '20%', width: '75%' }} /> */}
        <Typography className='title' sx={{ fontWeight: '700', fontFamily: 'Roboto', fontSize: "36px", marginLeft: '4%', marginTop: "10%", textAlign: "justify" }}>Online Learning Needs Offline Support </Typography>
      </Grid>
      <Grid item lg={9.5} xs={12} sx={{}}>
        <Zone />
      </Grid>
      <Grid item lg={12} xs={12} sx={{ textAlign: 'center' }}>
        <Typography sx={{ fontFamily: "Roboto", fontSize: '16px' }}>© 2023 Crestbell. All Rights Reserved.</Typography>
      </Grid>
    </Grid>
  )
}

export default Footer2
