import React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '7px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
   
    backgroundColor: theme.palette.mode === 'light' ? '#249428' : '#308fe8',
  },
}));
const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: '7px',
 
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    
    backgroundColor: theme.palette.mode === 'light' ? '#fd4c42' : '#308fe8',
  },
}));
const Rating1 = () => {
  const [value, setValue] = React.useState(4.5);
  return (
    <Box
    sx={{
        '& > legend': { mt: 2 },
    }}
>
    {/* <Typography component="legend">Controlled</Typography> */}
    {/* <Rating
name="simple-controlled"
value={value}
onChange={(event, newValue) => {
  setValue(newValue);
}}
/> */}
    <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem', marginTop: '1rem' }}>Course Reviews</Typography>
    <div style={{display: 'flex'}}>
        <Rating name="read-only" value={value} precision={0.5} readOnly />
        <Typography>4.84 out of 463 reviews</Typography>
    </div>
    <Rating name="read-only" value={value} precision={4} readOnly sx={{marginTop:'4%'}}/>
    <Grid container>
      <Grid item xs={10} lg={10} md={6} sm={6}>
         <BorderLinearProgress variant="determinate" value={82} sx={{}} />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} lg={1} >
        <Typography xs={{marginTop:'-15%'}}>82%</Typography>
      </Grid>
         </Grid>
         <Rating name="read-only" value={value} precision={4} readOnly />
    <Grid container>
      <Grid item xs={10} lg={10} md={6} sm={6}>
         <BorderLinearProgress variant="determinate" value={10} sx={{}} />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} lg={1}>
        <Typography xs={{}}>10%</Typography>
      </Grid>
         </Grid>  
         <Rating name="read-only" value={value} precision={4} readOnly />
         <Grid container>
      <Grid item xs={10} lg={10} md={6} sm={6}>
         <BorderLinearProgress1 variant="determinate" value={2} sx={{}} />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} lg={1}>
        <Typography xs={{}}>2%</Typography>
      </Grid>
         </Grid> 
          <Rating name="read-only" value={value} precision={4} readOnly /> 
         <Grid container>
      <Grid item xs={10} lg={10} md={6} sm={6}>
         <BorderLinearProgress1 variant="determinate" value={2} sx={{}} />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1} lg={1}>
        <Typography xs={{}}>2%</Typography>
      </Grid>
         </Grid>  
</Box>
  )
}

export default Rating1