import { Grid, Typography } from '@mui/material'
import React from 'react'
import about from './Images/about.jpg'
import './About.css';


const About = () => {
  return (
    <Grid Container className='typo' sx={{backgroundImage: `url(${about})`,backgroundColor: '#273773',  height:"650px", backgroundSize:'cover',backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', opacity:'0.7'}}>
        <Grid item sx={{backgroundColor: '#273773', opacity: 0.5, width: '100%', height: '100%', position: 'relative'}}>
        </Grid>
        <Grid item sx={{position: 'absolute', paddingTop:'10%', top:"20%", left:"45%"}}>
            <Typography variant="h3" color="white" sx={{fontWeight:'bold',fontFamily: "Roboto"}}>About Us</Typography>
            <hr color="#3feba3" width="95%"/>
        </Grid>
    </Grid>
  )
}

export default About
