import { Grid, Typography } from '@mui/material'
import React from 'react'

const TermsPage = () => {
  return (
    <Grid container sx={{marginTop:"150px"}}>
        <Grid item xs={12} sx={{textAlign:"center"}}>
    <Typography  variant="h3" sx={{pb:"3%", mt:"3%"}}>T & C / Guideline</Typography>
    </Grid> 
        <Grid item container lg={12}>
            <Grid item lg={2}/>
                <Grid item lg={8}><Typography><ol>
                    <li><div>It is the sole responsibility of the user enrolling into a program to check the accuracy of and evaluate the suitability and relevance of thr program selected. The enrollment inti a program is non-transferable.</div></li>
                    <li>Course would be started only after Completion of registtration & deposite of complete (Full Fee.)</li>
                    <li>Fee once paid would be non refundable & non transferable.</li>
                    <li>The student has to score minimum 50% at every level of evaluation.</li>
                    <li>It is the sole discretion of University to change course fee anythime. The course fee would be applicable as availabe on website at the time of registration.</li>
                </ol></Typography></Grid>
            <Grid item lg={2}/>
        </Grid>
    </Grid>
  )
}

export default TermsPage
