import React from 'react';
import ProdServ from '../Components/ProdServ';
import Product from '../Components/Product';
import Product1 from '../Components/Product1';

const ProductServices = () => {
  return (
    <div>
      <ProdServ/>
      <Product/>
      <Product1/>
    </div>
  )
}

export default ProductServices
