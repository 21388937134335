import React from 'react';
import About from '../Components/About';
import About1 from '../Components/About1';
import About2 from '../Components/About2';
import Expertise from '../Components/Expertise';

const AboutUs = () => {
  return (
    <div>
       <About/>
      <About1/>
      <About2/>
      <Expertise/>
    </div>
  )
}

export default AboutUs
