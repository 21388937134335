import React from 'react';
import { Grid,Typography,Button,Box,TextField } from '@mui/material';
import supp from './Images/supp.png'

const SupportTry = () => {
  return (
    <Grid container marginTop={'12%'}>
    <Grid container item lg={12} xl={12} sx={{ mt:'-7%', backgroundImage:`url(${supp})`, height: '740px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
      <Grid item sx={{backgroundColor: '#273773', opacity: 0.5, width: '100%', height: '100%', position: 'relative'}}>
      </Grid>
      <Grid container item sx={{position: 'absolute',marginTop:'3%'}} >        
      <Grid item lg={1.5} sm={1.5} md={1.5}  xl={1.5}/>
            <Grid item  lg={4} sm={4} md={4}  sx={{marginTop:'15%' }}>
                <Typography  sx={{ fontWeight: '500', mb: '2%',color:'white', fontFamily:'Roboto',fontSize:'2rem' }}>Technology & Marketing Partner</Typography>
                <Typography sx={{ fontWeight: '500',color:'white', fontFamily:'Roboto',fontSize:'18px',lineHeight:'1.2' }}>Maharaja Ranjit Singh</Typography>
                <Typography sx={{ fontWeight: '500' ,color:'white', fontFamily:'Roboto',fontSize:'18px',lineHeight:'1.2' }}>Punjab Technical University, BATHINDA</Typography>
                <div style={{display:'flex',justifyContent:'center'}}>
                   <Button variant="contained" size="large" sx={{ backgroundColor: "#3feba3", color: '#020139', textTransform: "capitalize" ,fontSize:'15px',fontWeight:'600',padding:'8px 30px',marginTop:'4%'}}>Brochure</Button> 
                </div>
                
            </Grid>
            <Grid item lg={0.5} xl={0.5} md={0.5} sm={0.5}></Grid>
            <Grid item lg={4.5} xs={12} sm={5} md={5} xl={4.5}>
                <Box sx={{ marginTop: '20px', pt:'4%',pl:'4%',pr:'4%',pb:'8%', bgcolor: 'transparent', backgroundImage: 'linear-gradient(180deg, #0036FF69 0%, #00FF9B57 100%)', borderRadius: '10px', boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 50%)', marginBottom: '2%' }}>
                    <Typography sx={{ textAlign: 'center', color: '#ffffff', fontSize: '30px', fontWeight: '500', fontFamily: 'Roboto' }}>
                    Apply For Associate Partner
                    </Typography>

                    <Grid item  lg={12} xl={12} sm={12} md={12} sx={{mb:'6%',display:'flex',justifyContent:'space-between'}}>
                        <Grid item lg={6} sm={6} md={6} xl={6} margin={'1%'}>
                        <TextField id="outlined-basic" placeholder="Full Name" variant="outlined" sx={{bgcolor:'white',width:'100%'}} inputProps={{style: {height: "10px"}}} />      
                        </Grid>

                        <Grid item lg={6} sm={6} md={6} xl={6}  margin={'1%'}>
                        <TextField id="outlined-basic" placeholder="Your Email" variant="outlined" sx={{bgcolor:'white',width:'100%'}} inputProps={{style: {height: "10px"}}} />    
                  
                        </Grid>
                    </Grid> 

                    <Grid item  lg={12} xl={12} sm={12} md={12} sx={{mb:'6%',display:'flex',justifyContent:'space-between'}}>
                        <Grid item lg={6} sm={6} md={6} xl={6} margin={'1%'}>
                        <TextField id="outlined-basic" placeholder="Phone Number" variant="outlined" sx={{bgcolor:'white',width:'100%'}} inputProps={{style: {height: "10px"}}} />      
                        </Grid>

                        <Grid item lg={6} sm={6} md={6} xl={6}  margin={'1%'}>
                        <TextField id="outlined-basic" placeholder="Your Address" variant="outlined" sx={{bgcolor:'white',width:'100%'}} inputProps={{style: {height: "10px"}}} />    
                  
                        </Grid>
                    </Grid> 

                    <Typography color="white" sx={{fontWeight:'600',mt:'6%'}}>Investment Capacity</Typography>
                    
                    {/* <Select className='dropp' defaultValue='Minimum 10 lakh' sx={{width:'100%', backgroundColor:'white'}} >
                    <MenuItem value="Minimum 10 lakh">Minimum 10 lakh</MenuItem>
                    </Select> */}
                    {/* <label for="cars">Choose a car:</label> */}
                    <Grid item lg={12}>
                    <select class="dropp" name="Minimum 10 lakh" id="Minimum 10 lakh" style={{width:'99%',height:'45px'}}>
                        <option value="volvo">Minimum 10 lakh</option>
                    </select>
                    </Grid>

                    <Typography color="white" sx={{fontWeight:'600',mt:'5%'}}>PAN India Zones</Typography>
                    <Grid item  lg={12} xl={12} sm={12} md={12} sx={{mb:'6%',display:'flex',justifyContent:'space-between'}}>
                        <Grid item lg={6} sm={6} md={6} xl={6}  margin={'1%'}>
                            <select class="dropp" name="PAN India Zones" id="PAN India Zones" style={{width:'100%',height:'45px'}}>
                                <option value="volvo">PAN India Zones</option>
                            </select>
                        </Grid> 

                        <Grid item lg={6} sm={6} md={6} xl={6}  margin={'1%'}>
                            <select class="dropp" name="States" id="States" style={{width:'100%', height:'45px'}}>
                                <option value="States">States</option>
                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>   
                                <option value="Assam">Assam</option>
                                <option value="Bihar">Bihar</option>
                                <option value="Chhattisgarh">Chhattisgarh</option>
                                <option value="Goa">Goa</option>
                                <option value="Gujarat">Gujarat</option>
                                <option value="Haryana">Haryana</option>
                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                <option value="Jharhand">Jharkhand</option>
                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                <option value="Kerela">Kerela</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Manipur">Manipur</option>
                                <option value="Meghalaya">Meghalaya</option>
                                <option value="Mizoram">Mizoram</option>
                                <option value="Nagaland">Nagaland</option>
                                <option value="Odisha">Odisha</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Rajasthan">Rajasthan</option>
                                <option value="Sikkim">Sikkim</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Telangana">Telangana</option>
                                <option value="Tripura">Tripura</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                <option value="Uttarakhand">Uttarakhand</option>
                                <option value="West Bengal">West Bengal</option>
                            </select>
                        </Grid>
                        
                    </Grid> 

                    <Grid item lg={12} xs={12} xl={12} sm={12} md={12} margin={'1%'}>
                    {/* <input placeholder='International Zone' style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Roboto', bgColor: '#ffffff', color: '#000000', padding: '12px', width: '84%', height: 'auto', marginTop: '6%' }} inputProps={{style: {height: "10px"},}}/> */}
                    <TextField placeholder='International Zone' sx={{fontSize: '16px', fontWeight: '400', fontFamily: 'Roboto', marginTop: '2%', backgroundColor:"white", width: '100%'}}  inputProps={{style: {height: "10px"},}}/>
                    </Grid>
                    <Button variant='contained' sx={{ textTransform: 'capitalize', bgcolor: '#273773', padding: '10px 35px 10px 35px', borderRadius: '5px 5px 5px 5px', marginTop: '6%', fontSize: '16px', width:'100%'}}>Next Step</Button>
                </Box>
            </Grid>
            <Grid item lg={1.5} sm={1.5} md={1.5}  xl={1.5}/>
      </Grid>

              </Grid>
              
  </Grid>
  )
}

export default SupportTry
