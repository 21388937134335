import { Grid, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import './privacy.css'

const Privacy = () => {
    return (
        <Grid container lg={12} md={12} sm={12} xs={12}>
            <Grid lg={2} md={1.3} sm={1.3} xs={0.3}/>
            <Grid item lg={8} md={9.4} sm={9.4} xs={11.4} sx={{ mt: '6rem' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '2.5rem', fontFamily: 'Roboto', mb: '1rem' }}>Privacy Policy</Typography>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: 'Bolder', mb: '1rem' }}>This Privacy Policy applies to the www.crestbellsupport.com</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', fontWeight: 400, mb: '3rem' }}>This website acknowledges the significance of maintaining your privacy. We value your privacy and appreciate your trust in us.
                    This Policy describes how we handle the user information we collect on www.crestbellsupport.com. This Privacy Policy applies to current and
                    former visitors to our website and to our future customers. By visiting and/or using our website, you agree to this Privacy Policy. This
                    website is a property of CRESTBELL SUPPORT PVT. LTD., a LLP company having its office Sector-34-A, Chandigarh, India.</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', fontWeight: 'Bolder', mb: '1rem' }}>INFORMATION AND DATA COLLECTION:</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', mb: '3rem' }}>
                    <ol>
                        <li><Typography sx={{ fontWeight: 'Bolder', display:"inline-block" }}>Contact information: </Typography> We collect your Name, Email, Phone, Address through form pages filled by our website visitors.</li>
                        <li><Typography sx={{ fontWeight: 'Bolder', display:"inline-block" }}>Information you post: </Typography> We collect the information you post in the form pages on our website or on a third-party social media site belonging to this website.</li>
                        <li><Typography sx={{ fontWeight: 'Bolder' , display:"inline-block"}}>Demographic information:</Typography> We collect demographic information or any other information provided by you during filling the form on our website.</li>
                        <li><Typography sx={{ fontWeight: 'Bolder' , display:"inline-block"}}>Others: </Typography> If you visit this website <NavLink to={'/'} className='link' style={{ textDecoration: 'underline', color:'#c36' }}>crestbellsupport.com</NavLink> , we collect information about your IP address and the browser you’re using for security reasons. We look at what site you came from, duration of time spent on our website, pages accessed, or what site you visit when you leave us</li>
                    </ol>
                </Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', fontWeight: 'Bolder', mb: '1rem' }}>TYPES OF INFORMATION COLLECTED BY US:</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', mb: '3rem' }}>
                    <ol>
                        <li>We collect information directly from you filled on the form provided on this website <NavLink to={'/'} className='link' style={{ textDecoration: 'underline', color:'#c36' }}>crestbellsupport.com</NavLink> .We also collect information if you post a comment on our websites or ask us a question through phone or email.</li>
                        <li>We collect information from you passively. We use tracking tools for web analytics to collect information about your usage of our website.</li>
                        <li> We get information about you from third parties. For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.</li>
                    </ol>
                </Typography>

                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', fontWeight: 'Bolder', mb: '1rem' }}>USAGE OF YOUR PERSONAL INFORMATION:</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', mb: '3rem' }}>
                    <ol >
                        <li>We use your personal information to contact you. We also use the information you provide to contact you for confirmation of a purchase and partnering on our website or for other promotional/marketing purposes.</li><br />


                        <li>We use the information to respond to your requests or questions or queries. We might use your information to confirm your payment.</li><br />


                        <li>We use the information to motivate you to improve our products and services through our Research and Incubation platform. We use your information to customize your experience with us and it includes displaying content based upon your preferences.</li><br />


                        <li>We use the information to look at site trends and customer interests. We may use your information to make our website and products exclusive from others. We combine the information we get from you with information about you we get from third parties.</li><br />


                        <li>We use the information for security purposes. We may use the information to protect our company, our customers, or our websites.</li><br />


                        <li>We use the information for marketing purposes. We send you information about special promotions or offers. We also tell you about new updations in our products. These are our own offers on our products, or third-party offers or products we think you might find suitable for you.</li><br />


                        <li>We offer newsletter to update you about us on the contact information provided by you.</li><br />


                        <li>We use the information to send you transactional communications. We might send you emails or SMS about your account or a purchase or partnering or associating with us.</li><br />
                        <li>We use information as otherwise permitted by law.</li>
                    </ol>
                </Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', fontWeight: 'Bolder', mb: '1rem' }}>SHARING OF INFORMATION WITH THIRD-PARTIES:</Typography>
                <Typography sx={{ fontFamily: 'Roboto', color: '#333', fontSize: '1rem', mb: '1rem' }}>
                    <ol>
                        <li><Typography sx={{ fontWeight: 'Bolder' }}>EMAIL OPT-OUT:</Typography>
                            <p>You can opt-out of receiving our marketing emails. To stop receiving our promotional emails, please email <a className='link' href='mailto:info@crestbellsupport.com' style={{ color:'#c36'}}>info@crestbellsupport.com</a></p>

                            It may take about 7 to 10 working days to process your request. Even if you opt-out of getting marketing messages, we will still be sending you transactional messages through email and SMS about your purchases or partnering or associating.</li><br/>



                        <li><Typography sx={{ fontWeight: 'Bolder' }}>THIRD-PARTY SITES:</Typography>
                            If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not responsible for the third party sites.</li><br/>



                        <li><Typography sx={{ fontWeight: 'Bolder' }}>UPDATES TO THIS POLICY</Typography>
                            From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</li><br/>



                        <li><Typography sx={{ fontWeight: 'Bolder' }}>JURISDICTION</Typography>
                            If you choose to visit the website, your visit and any dispute over privacy are subject to this Policy and the website’s terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India</li><br/>
                    </ol>
                </Typography>
            </Grid>
            <Grid lg={2}  md={1.3} sm={1.3} xs={0.3} />
        </Grid>
    )
}

export default Privacy
