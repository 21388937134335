import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderRadius: '5px',
    marginBottom: '2%'
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const CourseContent = ({ courseArray }) => {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const arr = [
    {
      topic: 'Introduction',
      para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolorsit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget.'
    },
    {
      topic: 'SQL Server languages and Relational databases',
      para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolorsit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,sit amet blandit leo lobortis eget.'
    }

  ]

  const topicArr = [{name:"Recording", topics:[{name:"Fundamentals of Accounting",subTopics:[{name:"Summarizing the Fundamentals of Accountancy"},{name:"Staring with the accounting terms"},{name:"Concepts of double entry system of accounting"},{name:"Usage of Golden rules of Accounting in the books of Accounting"},{name:"Recording the business transations"}]},
  {name:"Introduction to Tally Prime",subTopics:[{name:"Introduction & download and install Tally in the system."},{name:"Creating a company and the company features and configurations."}]},
  {name:"Maintaining Chart of Accounts",subTopics:[{name:"Create,alter and display the Chart of Accounts such as Ledgers,Groups,Stock items."},{name:"Units of Measure Creation ."}]}
]},]

  return (
    <Box>
      <Typography sx={{ fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif', fontSize: '1.5rem', fontWeight: '700', color: '#283034', letterSpacing: '-0.5px', marginBottom: '1rem', marginTop: '1rem' }}>
        Course content
      </Typography>
      {topicArr.map((item, index) => {
        return (
          <Accordion expanded={expanded === `panel${index+1}`} onChange={handleChange(`panel${index+1}`)}>
            <AccordionSummary aria-controls={`panel${index+1}d-content`} id={`panel${index+1}d-header`}>
              <Typography sx={{fontFamily: 'Roboto,Helvetica Neue,Helvetica,Arial,sans-serif'}}>{item.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.topics.map((topic, topicIndex) => {
                return (
                  <div key={topic.id}>
                    <ul
                      className="txt-list mb-10"
                      style={{ listStyleType: "square" }}
                    >
                      <li>
                        <b>{topic.name}</b>
                      </li>
                    </ul>
                    {/* <br/> */}
                    {/* <br/> */}
                    <ul
                      className="txt-list mb-10 subtopic"
                      style={{ listStyleType: "circle" }}
                    >
                      {topic.subTopics.map(
                        (subTopic, subTopicIndex) => {
                          return (
                            <li key={subTopic.id} style={{marginLeft:'20px'}}>
                              {subTopic.name}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}


    </Box>
  )
}

export default CourseContent