import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { Javascript } from '@mui/icons-material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    
   

};

export default function ModalJobs({ setShow, data,handleClose }) {


    return (
        <div >
           
            {data ? <Fade in={true}>
                <Box sx={style} style={{marginTop:"200px"}}>

                <Grid container sx={{justifyContent:'space-between'}}>
                    <h4>{data.zone}</h4>
                    <button onClick={handleClose} style={{height:'28px'}}>x</button>
                </Grid>
                    {data.dataArr ? <>
                        <TableContainer component={Paper} sx={{ marginTop: "10px" ,border:'1px solid grey '}} >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center'>Name</TableCell>
                                        <TableCell align='center'>Location</TableCell>
                                        <TableCell align='center'>Address</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.dataArr.map((item) => (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {item.Name}
                                            </TableCell>
                                            <TableCell align="center">{item.Location}</TableCell>
                                            <TableCell align="center">{item.Address}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        </> : <><h6 >{data.address ? <>Address: {data.address}</> : ""}</h6>
                        <h5>Name:  <a href={`mailto:${data.email}`}>{data.email}</a></h5>
                        <h5>Contact:<a href={`tel:${data.phone}`}> {data.phone}</a></h5>
                        <h6>{data.website ? <>Website: <a href={data.website} target="_blank" style={{ textDecoration: 'underline' }}>{data.website}</a></> : ""}</h6>
                        <h6>{data.info}</h6></>}
                </Box>
                </Fade>: null}
        </div>
    );
}
